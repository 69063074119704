import * as React from 'react'
import { dataPrecisionSetup } from '@/utils/cammedFormat'
import { Table, Row, Th, Td } from '@/components/Table'
import { Input } from '@/components/Input'
import { Button } from '@/components/Button'
//import { SearchTransportation } from '@/components/SearchTransportation'

type RoadTransportRowProps = {
  index: number
  road_transport: object
  data: any
  setData: (data: object) => void
  dataLatLng: LatLng
  defaultRoadTransportData: object
  requestParamName: string
  projectsLayouts?: string[]
}

export const RoadTransportRow: React.FC<RoadTransportRowProps> = (props) => {
  const {
    index,
    road_transport,
    data,
    setData,
    dataLatLng,
    defaultRoadTransportData,
    requestParamName,
    projectsLayouts,
  } = props
  const onChangeData = (value, index, column) => {
    const road_transports = [...data.road_transports]
    road_transports.splice(index, 1, {
      ...road_transports[index],
      [column]: value,
    })
    setData({ ...data, road_transports })
  }
  const onClickAddRoadTransportBlock = (e) => {
    e.preventDefault()
    setData({
      ...data,
      road_transports: [...data.road_transports, defaultRoadTransportData],
    })
    // 交通機関を検索
    //searchTransportaiton(data, setData, dataLatLng)
  }
  const onCLickDeleteRoadTransportBlock = (e) => {
    e.preventDefault()
    const newRoadTransports = [...data.road_transports]
    newRoadTransports.splice(index, 1)
    setData({
      ...data,
      road_transports: newRoadTransports,
    })
  }
  // TEMPORARY: replacing "mins" because after OCR released, "mins" automatically attached as suffix in form value.
  const replaceOcrInsertString = (value: string | null | undefined): string => {
    if (!value) {
      return ''
    }

    return value
      .toString()
      .replace(/mins|min/, '')
      .trim()
  }

  return (
    <Row
      label={
        projectsLayouts ? (
          <Th projects_layouts={projectsLayouts}>交通機関（IC）{index > 0 && ` ${index + 1}`}</Th>
        ) : (
          <Th>交通機関（IC）{index > 0 && ` ${index + 1}`}</Th>
        )
      }
    >
      <Td>
        <div className="py-1 flex gap-4 flex-col md:flex-row">
          <Input
            multiple
            className="relative md:w-[300px] flex-shrink-0"
            value={road_transport.road_name || ''}
            name={`${requestParamName}[road_name][]`}
            id={`${requestParamName}_road_name`}
            prefix="　道路　"
            onChange={(e) => onChangeData(e.target.value, index, 'road_name')}
          />
          <Input
            multiple
            className="relative md:w-[300px] flex-shrink-0"
            value={road_transport.ic_name || ''}
            name={`${requestParamName}[ic_name][]`}
            id={`${requestParamName}_ic_name`}
            prefix="　IC名　"
            onChange={(e) => onChangeData(e.target.value, index, 'ic_name')}
          />
        </div>
        <div className="py-1 flex gap-4 flex-col md:flex-row">
          <Input
            multiple
            className="relative md:w-[300px] flex-shrink-0"
            value={replaceOcrInsertString(road_transport.distance_to_destination)}
            name={`${requestParamName}[distance_to_destination][]`}
            id={`${requestParamName}_distance_to_destination`}
            prefix="　距離　"
            suffix="km"
            onChange={(e) => onChangeData(e.target.value, index, 'distance_to_destination')}
            onBlur={(e) =>
              onChangeData(
                dataPrecisionSetup(e.target.value, 5, 2),
                index,
                'distance_to_destination'
              )
            }
          />
          <Input
            multiple
            className="relative md:w-[300px] flex-shrink-0"
            value={road_transport.estimated_time_minutes || ''}
            name={`${requestParamName}[estimated_time_minutes][]`}
            id={`${requestParamName}_estimated_time_minutes`}
            prefix="所要時間"
            suffix="分"
            onChange={(e) => onChangeData(e.target.value, index, 'estimated_time_minutes')}
            onBlur={(e) =>
              onChangeData(
                dataPrecisionSetup(e.target.value, 4, 0),
                index,
                'estimated_time_minutes'
              )
            }
          />
        </div>
        <div className="flex gap-2 mt-2">
          {data?.road_transports.length !== 1 && (
            <Button
              outline
              variant="red"
              size="small"
              onClick={(e) => onCLickDeleteRoadTransportBlock(e)}
            >
              削除
            </Button>
          )}
          {data?.road_transports.length === index + 1 && (
            <Button outline size="small" onClick={(e) => onClickAddRoadTransportBlock(e)}>
              追加
            </Button>
          )}
        </div>
      </Td>
    </Row>
  )
}
