import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Button, AnchorButton } from '@/components/Button'
import ClearIcon from '@material-ui/icons/Clear'
import { JreitPropertyPartsModal } from '@/components/Page/Properties/Detail/Surrounding/JreitPropertyPartsModal'
import { jReitMapLink } from '@/models/constUrl'



type Props = {
  isOpen: boolean;
  isJreitPropertyLinks?: any;
  property:any;
  purpose:any;
}


export const JreitPropertyModal: React.FC<Props> = ({
    isOpen = false,
    isJreitPropertyLinks,
    property,
    purpose
}) => {
  const [isAlertOpen, setAlertOpen] = React.useState(false)
  const closeAlertModal = () => {
    setAlertOpen(false);
  };

  const openAlertModal = () => {
    setAlertOpen(true);
  };

  // 以下のコメントアウトはDebugように残しておいてください
  console.log(property?.prefecture,property?.city)
  const isJreit = [property?.jreit_id,property?.jreit_password] || [null, null];

  return (
    <>
      <div
        style={{ display: isOpen ? 'inline-flex' : 'none' }}
        className={`z-10 text-[#777] inline-flex px-6 md:px-8 py-[8px] mt-[1px] bg-[#f4f5f6] shadow-[inset_2px_-2px_2px_rgba(0,0,0,0.1)] select-none hover:bg-targetLine cursor-pointer`}
        onClick={openAlertModal}
      >
        {purpose}
      </div>

      <JreitPropertyPartsModal
        title={purpose}
        isAlertOpen={isAlertOpen}
        closeAlertModal={closeAlertModal}
        isJreitPropertyLinks={isJreitPropertyLinks}
        property={property}
        isJreit={isJreit}
        purpose={purpose}
      />

    </>
  )
}

export default JreitPropertyModal
