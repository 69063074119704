export const isCopyWriteText = (copyValue,setShowCopiedMessage,messgageFlg) => {
  navigator.clipboard.writeText(copyValue)
  .then(() => {
    console.log("コピー成功:", copyValue);
  })
  .catch(err => {
    console.error("コピー失敗:", err);
  });
  if(messgageFlg === true){
    setShowCopiedMessage(true);
    setTimeout(() => setShowCopiedMessage(false), 1000);
  }
};