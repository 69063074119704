import * as React from 'react'
import { Table, Row, Th, tr, Td } from '@/components/Table'
import { Textarea } from '@/components/Textarea'
import { Button } from '@/components/Button'
import { format, parseISO } from 'date-fns'
import type { Property, Memo } from '@/types/property'

export const CommentForm: React.FC<{
  memosData: Memo[]
  property: Property
  newMemoChange?: (value: string) => void
  setListMemos?: (memos: Memo[]) => void
  commentFormType?: string
}> = ({ memosData = [], property, newMemoChange, setListMemos, commentFormType }) => {
  const [memos, setMemos] = React.useState([])
  const [newMemo, setNewMemo] = React.useState('')
  const csrfToken: HTMLMetaElement = document.head.querySelector('meta[name="csrf-token"]')

  React.useEffect(() => {
    setMemos(memosData)
  }, [memosData])

  const setPropertyNewMemo = (value: string) => {
    setNewMemo(value)
    if(newMemoChange) {
      newMemoChange(value)
    }
  }

  const copyMemo = (value: string) => {
    setNewMemo(value)
  }

  const createMemo = React.useCallback(() => {
    // 新規作成
    fetch(`/properties/${property.hashid}/memos`, {
      method: 'POST',
      body: JSON.stringify({ content: newMemo }),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken.content,
      },
    })
      .then((res) => res.json())
      .then((memo) => {
        setMemos([memo, ...memos])
        setPropertyNewMemo('')
        if(setListMemos){
          setListMemos([memo, ...memos])
        }
      })
      .catch(() => {
        alert('作成に失敗しました')
      })
  }, [memos, newMemo])

  const deleteMemo = React.useCallback(
    (id: number) => {
      if (!confirm('削除してもよろしいですか？')) {
        return
      }
      fetch(`/properties/${property.hashid}/memos/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken.content,
        },
      })
        .then(() => {
          const newMemos = [...memos]
          const deleteIndex = memos.findIndex((memo) => memo.id === id)
          newMemos.splice(deleteIndex, 1)
          setMemos(newMemos)
          if(setListMemos) {
            setListMemos(newMemos)
          }
        })
        .catch(() => {
          alert('削除に失敗しました')
        })
    },
    [memos]
  )

  return (
    <>
    {commentFormType == 'modal' ? (
      <>
        <Table
          className="border-t border-b block mb-8"
        >
          <tbody className="block md:table-row-group">
            <tr
              className="bg-white border-b border-gray-100">
              <td colSpan={2} className="p-4">
                <div className="mb-2 font-bold">新規進捗メモ</div>
                <div className="mb-2">
                  <Textarea
                    className="w-full resize-y"
                    value={newMemo}
                    onChange={(e) => {
                      setPropertyNewMemo(e.target.value)
                    }}
                  />
                  <div className="flex justify-end mt-2 mb-2">
                    <div className="mr-2">
                      <Button
                        type="button"
                        className="text-sm"
                        variant="text"
                        onClick={() => {
                          setPropertyNewMemo('')
                        }}
                      >
                        入力リセット
                      </Button>
                    </div>
                    <div>
                      <Button
                        type="button"
                        className="text-sm"
                        onClick={() => {
                          createMemo()
                        }}
                      >
                        保存
                      </Button>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="h-[50vh] overflow-y-auto">
          <Table>
            <tbody>
            {memos.map((memo, i) => (
              <tr
                key={i}
                className="bg-white border-b border-gray-100 hover:bg-targetLine cursor-pointer"
              >
                <Td>
                  <div className="mr-4">
                    <p className="mb-4">
                      {format(parseISO(memo.updated_at), 'yyyy/MM/dd')} {memo?.user?.name ?? '不明'}
                    </p>
                    <p className="whitespace-pre-wrap break-all">
                      {memo.content}
                    </p>
                  </div>
                </Td>
                <Td>
                  <div className="flex justify-end">
                    <div className="mr-2">
                      <button
                        className="border border-primary-font text-primary-font hover:text-white hover:bg-primary-font text-xs py-[1px] px-2"
                        type="button"
                        onClick={() => {
                          copyMemo(memo.content)
                          setPropertyNewMemo(memo.content)
                        }}
                      >
                        引用
                      </button>
                    </div>
                    <div className="mr-2">
                      <button
                        type="button"
                        className="border border-red-700 text-red-700 hover:text-white hover:bg-red-700 text-xs py-[1px] px-2"
                        onClick={() => {
                          deleteMemo(memo.id)
                        }}
                      >
                        削除
                      </button>
                    </div>
                  </div>
                </Td>
              </tr>
          ))}
          </tbody>
        </Table>
      </div>
    </>
    ) : (
      <Table
        className="border-t border-b block mb-16"
        header={
          <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
            進捗メモ
          </div>
        }
      >
        <tbody className="block md:table-row-group">
          <Row label={<Th projects_layouts={['進捗新規メモ']}>進捗新規メモ</Th>}>
            <Td>
              <div className="flex items-center">
                <Textarea
                  className="w-full min-h-[47px] mr-4 resize-y"
                  value={newMemo}
                  onChange={(e) => {
                    setPropertyNewMemo(e.target.value)
                  }}
                />
                <div className="ml-auto">
                  <Button
                    type="button"
                    outline
                    onClick={() => {
                      createMemo()
                    }}
                  >
                    追加
                  </Button>
                </div>
              </div>
            </Td>
          </Row>
          {memos.map((memo, i) => (
            <Row key={i} label={<Th>{format(parseISO(memo.updated_at), 'yyyy/MM/dd')}</Th>}>
              <Td>
                <div className="flex items-center">
                  <div className="mr-4">
                    <p className="whitespace-pre">
                      {memo?.user?.name ?? '不明'} : {memo.content}
                    </p>
                  </div>
                  <div className="ml-auto">
                    <Button
                      type="button"
                      outline
                      variant="red"
                      onClick={() => {
                        deleteMemo(memo.id)
                      }}
                    >
                      削除
                    </Button>
                  </div>
                </div>
              </Td>
            </Row>
          ))}
        </tbody>
      </Table>
    )}
  </>
  )
}

export default CommentForm
