import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Button, AnchorButton } from '@/components/Button'
import ClearIcon from '@material-ui/icons/Clear'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { isCopyWriteText } from '@/components/EventAction/Click'

type Props = {
  title: string
  isAlertOpen: boolean
  closeAlertModal: any
  isJreitPropertyLinks?: any;
  property:any;
  isJreit: string[];
  purpose?: string
}

export const JreitPropertyPartsModal: React.FC<Props> = ({
    title,
    isAlertOpen,
    closeAlertModal,
    isJreitPropertyLinks,
    property,
    isJreit,
    purpose="REIT.map"
}) => {
  const [showCopiedMessage, setShowCopiedMessage] = React.useState(false);
  const [copiedMessageValue, setCopiedMessageValue] = React.useState<string>("");
  const handleCopyClick = (text: string, message: string) => {
    isCopyWriteText(text, setShowCopiedMessage, true);
    setCopiedMessageValue(message);
  };
  if (purpose === "REIT.map") {
    const [jReitID, jReitPASS] = isJreit || [null, null];
    const addMessage = (add: string) => jReitID === '' || jReitPASS === '' ? `${add}を設定してください` : `${add}をコピーしました`;
    return (
      <>
          <Transition appear show={isAlertOpen} as={React.Fragment}>
            <Dialog className="relative z-10" onClose={closeAlertModal}>
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>
              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center text-center">
                  <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                    >
                    <Dialog.Panel className="w-screen md:max-w-[625px] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                      <Dialog.Title className="text-base font-medium p-4 leading-6 text-gray-700">
                        {title}
                        <ClearIcon className="float-right cursor-pointer" onClick={closeAlertModal} />
                      </Dialog.Title>
                      <hr />
                      <p className='p-[1.5rem] text-[0.85rem]'>※ご利用にあたり、詳細は外部リンク先サービスの利用規約をご参照ください。</p>
                      <div 
                        className="flex justify-center items-center text-center m-4 border cursor-pointer hover:bg-[rgb(97,140,165)] hover:text-[white] active:bg-[rgb(101,119,130)] p-4 rounded-[8px]"
                        onClick={() => handleCopyClick(jReitID, "jReitID")}
                        >
                        <p>REIT.map ID: {jReitID}</p>
                        <button 
                          className="bg-[rgb(9_104_157)] text-[white] p-[5px] m-[5px] flex items-center gap-4 rounded-[50px]"
                          >
                          <ContentCopyIcon />
                        </button>
                      </div>
                      <div 
                        className="flex justify-center items-center text-center m-4 border cursor-pointer hover:bg-[rgb(97,140,165)] hover:text-[white] active:bg-[rgb(101,119,130)] p-4 rounded-[8px]"
                        onClick={() => handleCopyClick(jReitPASS, "jReitPass")}
                        >
                        <p>REIT.map PW: {jReitPASS}</p>
                        <button 
                          className="bg-[rgb(9_104_157)] text-[white] p-[5px] m-[5px] flex items-center gap-4 rounded-[50px]"
                          >
                          <ContentCopyIcon />
                        </button>
                      </div>
                      {showCopiedMessage && (
                        <div className="bg-[rgb(9_104_157)] text-center">
                          <p className="text-[white]">
                            {addMessage(copiedMessageValue)}
                          </p>
                        </div>
                      )}
                      <div className="text-center m-4">
                        <div className='w-full flex justify-center items-center'>
                          <a
                            href={`${isJreitPropertyLinks}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className='my-5'
                            >
                            <Button
                              className="text-white w-[120px]"
                              size="small"
                              variant="primary"
                              >
                              確認しました
                            </Button>
                          </a>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </>
      )
    } else if ("REITDBMAP") {
      const addMessage = (add: string) => `${add}をコピーしました`;
      const propertyLocation = `${property?.prefecture + property?.city + property?.town + property?.chome}`
        return (
            <>
              <Transition appear show={isAlertOpen} as={React.Fragment}>
                <Dialog className="relative z-10" onClose={closeAlertModal}>
                  <Transition.Child
                    as={React.Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                  </Transition.Child>
        
                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center text-center">
                      <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="w-screen md:max-w-[625px] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                          <Dialog.Title className="text-base font-medium p-4 leading-6 text-gray-700">
                            {title}
                            <ClearIcon className="float-right cursor-pointer" onClick={closeAlertModal} />
                          </Dialog.Title>
                          <hr />
                          <p className='p-[1.5rem] text-[0.85rem]'>
                            ※ご利用にあたり、詳細は外部リンク先サービスの利用規約をご参照ください。<br/>
                          </p>
                          <div 
                            className="flex justify-center items-center text-center m-4 border cursor-pointer hover:bg-[rgb(97,140,165)] hover:text-[white] active:bg-[rgb(101,119,130)] p-4 rounded-[8px]"
                            onClick={() => isCopyWriteText(propertyLocation, setShowCopiedMessage, true)}
                          >
                            <p>所在地（住居表示）: {propertyLocation}</p>
                            <button 
                              className="bg-[rgb(9_104_157)] text-[white] p-[5px] m-[5px] flex items-center gap-4 rounded-[50px]"
                            >
                              <ContentCopyIcon />
                            </button>
                          </div>
                          {showCopiedMessage && (
                            <div className="bg-[rgb(9_104_157)] text-center">
                              <p className="text-[white]">
                                {addMessage("所在地（住居表示件名）")}
                              </p>
                            </div>
                          )}
                          <div className='w-full flex justify-center items-center'>
                            <a
                              href={`${isJreitPropertyLinks}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className='my-5'
                            >
                              <Button
                                className="text-white w-[120px]"
                                size="small"
                                variant="primary"
                              >
                                確認しました
                              </Button>
                            </a>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
            </>
        )
    }
}

export default JreitPropertyPartsModal
