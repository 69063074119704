import { Input } from '@/components/Input'
import { ocrExecution } from '@/components/Ocr'
import * as React from 'react'
import { useDropzone } from 'react-dropzone'

interface Props {
  id?: string
  name?: string
  className?: string
  hidden?: boolean
  accept?: any
  onDrop?: (acceptedFiles) => void
  filenameInitial?: string
  setOcr?: React.Dispatch<React.SetStateAction<ocrExecution>>
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export interface FileUploadHandle {
  clearFile(): void
  setFile(base64: string | File, filenameOverwrite?: string): void
}

export const FileUpload = React.forwardRef<FileUploadHandle, Props>(
  ({ id, name, className = '', hidden, accept, onDrop, filenameInitial, setOcr, onChange, ...props }: Props, ref) => {
    const [filename, setFilename] = React.useState<string>(filenameInitial || '')
    const inputRef = React.useRef<HTMLInputElement>(null)

    const onDropFunc = (acceptedFiles) => {
      const file = acceptedFiles[0]
      if (!file) {
        alert('ファイル形式を確認してください')
        return
      }
      const transfer = new DataTransfer()

      setFilename(file.name)
      transfer.items.add(file)
      // DataTransferを経由しないと設定できない
      inputRef.current.files = transfer.files

      if (onDrop) {
        onDrop(file)
      }
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop: onDropFunc,
      accept: accept || acceptDefault,
    })

    React.useImperativeHandle(ref, () => ({
      clearFile() {
        // ファイル名をクリア
        setFilename('')

        // ファイルをクリア
        const transfer = new DataTransfer()
        inputRef.current.files = transfer.files
      },
      setFile(fileData: string | File, filenameOverwirte?: string) {
        let file: File
        if (typeof fileData === 'string') {
          const mimeType = fileData.split(';')[0].split(':')[1]
          const bin = atob(fileData.replace(/^.*,/, ''))
          const buffer = new Uint8Array(bin.length)
          for (let i = 0; i < bin.length; i++) {
            buffer[i] = bin.charCodeAt(i)
          }
          file = new File([buffer.buffer], filenameOverwirte || filename, { type: mimeType })
        } else {
          file = fileData
        }
        const transfer = new DataTransfer()
        transfer.items.add(file)
        inputRef.current.files = transfer.files
      }
    }))

    return (
      <>
        {!hidden && (
          <div
            id={id}
            className={[
              'md:flex bg-gray-light p-6 border-2 border-dashed cursor-pointer',
              isDragActive && 'border-primary',
              className,
            ].join(' ')}
            {...getRootProps()}
          >
            <Input
              className="mb-3 md:mb-0 md:w-[246px] md:mr-3 pointer-events-none"
              placeholder="選択されていません"
              readOnly
              value={filename}
              onChange={onChange}
            />
            <label htmlFor="property_attached_files" className={styles.fileButton}>
              <input className="absolute w-0 h-0 opacity-0 hidden" {...getInputProps()} />
              ファイル選択
            </label>
          </div>
        )}
        <input type="file" className="hidden" name={name || 'file'} ref={inputRef} />
      </>
    )
  }
)

const acceptDefault = {
  'image/jpeg': [],
  'image/png': [],
  'application/pdf': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  'application/msword': [],
  'application/zip': [],
}

const styles = {
  fileButton: `
    md:mr-6
    flex
    md:inline-flex
    align
    items-center
    justify-center
    gap-1.5
    rounded-sm
    leading-none
    duration-200
    transition-opacity
    hover:opacity-80
    rounded-sm
    border
    box-border
    border-primary-font
    text-primary-font
    py-[5px]
    md:py-[7px]
    px-4
    text-sm
    cursor-pointer
  `,
}

FileUpload.displayName = 'FileUpload'