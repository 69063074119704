import { Button } from '@/components/Button'
import { Row } from '@/components/Table'
import { Property } from "@/types/property"
import { PropertyDocument } from "@/types/propertyDocument"
import { Tab } from '@headlessui/react'
import { Table } from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import { format } from "date-fns"
import * as React from "react"
import Map from "./Map"

type FormatDetail = {
  colspan: number
  column_name_1: string
  column_name_2: string
  correction_text: string
  created_at: string
  element_name: string
  id: number
  order: number
  position: string
  public_file_detail_id: number
  rowspan: number
  updated_at: string
  public_file_correction: {
    correction_text: string
    position: string
  }
}

type AttachedFile = {
  filename: string
  preview_url: string
  download_url: string
  content_type: string
}

type Props = {
  company_name: string
  property: Property
  property_document: PropertyDocument
  property_image: string // URL
  format_details: FormatDetail[]
  attached_files: AttachedFile[]
  google_maps_api_key: string
}

type TabType = 'files' | 'info'

const Show: React.FC<Props> = (props) => {
  const [tab, setTab] = React.useState<TabType>('info')

  const onChangeTab = (index: number) => {
    setTab(index === 0 ? 'info' : 'files')
  }

  return <div className="bg-gray-50">
    <div className="bg-white lg:mx-auto max-w-[1600px] lg:max-w-[1600px] lg:flex block">
      <div className="bg-white p-2 pt-4 sm:p-16 lg:p-8 w-full lg:max-w-[60%]">

        <div className='flex flex-col gap-1'>
          <div>{props.company_name}</div>
          <h1 className="text-3xl font-bold">{props.property.name || "物件概要書"}</h1>

          <div className="flex text-gray-300 justify-between flex-wrap mb-4 md:mb-12">
            <div className="">{props.property.prefecture} {props.property.city} {props.property.town} {props.property.chome}</div>
            <div>{format(new Date(props.property_document.updated_at), 'yyyy年MM月dd日')}</div>
          </div>
        </div>

        <Tab.Group onChange={onChangeTab}>
          <div className="flex text-sm overflow-x-auto whitespace-nowrap w-full">
            <Tab.List className="flex items-center border-b-[1px] border-primary mb-2 md:w-full">
              <Tab className={`${styles['tab']} ${styles[tab === 'info' ? 'true' : 'false']} border-l-[1px]`}>
                物件概要
              </Tab>
              <Tab className={`${styles['tab']} ${styles[tab === 'files' ? 'true' : 'false']}`}>
                物件資料
              </Tab>
            </Tab.List>
          </div>
          <Tab.Panels>
            <Tab.Panel unmount={false}>
              <Details details={props.format_details} />
            </Tab.Panel>
            <Tab.Panel unmount={false}>
              <Files files={props.attached_files} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>

      </div>
      <div className={`flex-none w-full lg:min-w-[280px] lg:max-w-[40%] sm:p-16 lg:p-0 flex flex-col gap-4 sm:gap-8 lg:gap-0 lg:h-screen ${props.property_image ? 'h-[1020px] gap-4' : 'h-[620px]'}`}>
        <div className={`w-full ${props.property_image ? 'h-[50%]' : 'h-full lg:h-[50%]'}`}>
          <Map
            property={props.property}
            google_maps_api_key={props.google_maps_api_key}
            error_messages={[]}
          />
        </div>
        {props.property_image ? <img src={props.property_image} className='w-full h-[50%] object-contain' /> : <div className='w-full h-[50%]' />}
      </div>
    </div>
  </div>
}

const styles = {
  tab: `
    inline-flex
    outline-none
    select-none
    text-sm
    py-2
    px-8
    border-t-[1px]
    border-r-[1px]
    border-primary
  `,
  true: `
    bg-primary
    text-white
  `,
  false: `
    text-primary-font
    border-t-[1px]
    border-r-[1px]
    border-primary
  `,
}

type DetailsProps = { details: FormatDetail[] }
const Details = (props: DetailsProps) => {

  const replaceElementName = (format_detail: FormatDetail): string => {
    const element_name: string = format_detail.element_name
    if (!format_detail.public_file_correction || !format_detail.public_file_correction.correction_text) {
      return element_name
    }
    const correction_text: string = format_detail.public_file_correction.correction_text
    switch (format_detail.public_file_correction.position) {
      case '1':
        return `${correction_text} ${element_name}`
      case '2':
        return `${element_name} ${correction_text}`
      case '3':
        return correction_text
      default:
        return element_name
    }
  }

  return <table className="w-full text-sm text-left text-black-base border-t border-t-gray-200 border-b border-b-gray-20 table-auto">
    {props.details.map((fd, i) => {
      return <Row key={i} className=' border-y-2'>
        {fd.column_name_1 && <th rowSpan={fd.rowspan} colSpan={fd.colspan} className='p-4 min-w-[120px] max-w-1/3'>{fd.column_name_1}</th>}
        {fd.column_name_2 && <th className='p-4 min-w-[120px] max-w-1/3'>{fd.column_name_2}</th>}
        <td className="w-full p-4">{replaceElementName(fd)}</td>
      </Row>
    })}
  </table>
}

type FilesProps = { files: AttachedFile[] }
const Files = (props: FilesProps) => {
  const [selectedFile, setSelectedFile] = React.useState<AttachedFile | null>(null)

  const allDownload = () => {
    props.files.forEach(file => {
      const a = document.createElement('a')
      a.href = file.download_url
      a.download = file.filename
      a.click()
    })
  }

  return <>
    <div className="flex justify-end">
      <Button
        className="text-primary-font m-0 bg-white"
        outline
        size="small"
        variant="primary"
        prefix={<CloudDownloadIcon fontSize="small" />}
        onClick={allDownload}
      ><span className="hidden md:inline">資料を一括ダウンロード</span></Button>
    </div>

    <div className="w-full text-right text-primary text-xs my-2">※プレビューはpdf/png/jpegのみご利用可能です</div>
    <div className='flex'>
      <div className="overflow-x-scroll w-full">
        <Table className="py-8">
          <thead className="bg-gray-150 text-sm sticky">
            <tr className="whitespace-nowrap">
              <th scope="col" className="py-2 px-4 font-medium bg-gray-150 text-left">資料名</th>
            </tr>
          </thead>
          <tbody className="whitespace-nowrap">
            {props.files.map((file, i) => {
              return <tr className={`whitespace-nowrap h-10 ${selectedFile?.filename === file.filename && "bg-targetLine"}`} key={i}>
                <td scope="col" className="py-2 px-4 font-medium w-full">{file.filename}</td>
              </tr>
            })}
          </tbody>
        </Table>
      </div>

      <div className="flex-1">
        <Table className="py-8">
          <thead className="bg-gray-150 text-sm sticky">
            <tr className="whitespace-nowrap">
              <th scope="col" colSpan={2} className="py-2 px-4 font-medium sticky right-0 bg-gray-150">
                操作
              </th>
            </tr>
          </thead>
          <tbody className="whitespace-nowrap">
            {props.files.map((file, i) => {
              return <tr className={`whitespace-nowrap h-10 ${selectedFile?.filename === file.filename && "bg-targetLine"}`} key={i}>
                <td className="text-center sticky xs:right-6 md:right-[140px] xs:w-6 bg-white"><a href={file.download_url} target="_blank" rel="noreferrer">
                  <Button
                    className="text-primary-font m-0 bg-white"
                    outline
                    size="small"
                    variant="primary"
                    prefix={<CloudDownloadIcon fontSize="small" />}
                  >
                    <span className="hidden md:inline">ダウンロード</span>
                  </Button></a></td>
                <td className="text-center xs:w-6 sticky md:right-0 xs:right-[-38px] md:bg-white">
                  {/* pdfもしくは画像の場合のみプレビューボタンをdisabledして薄くする(完全に消すと不自然なため) */}
                  <Button
                    className={"text-primary-font m-0 bg-white disabled:opacity-30"}
                    outline
                    size="small"
                    variant="primary"
                    onClick={() => setSelectedFile(file)}
                    prefix={<FindInPageIcon fontSize="small" />}
                    disabled={!file.content_type.includes('pdf') && !file.content_type.includes('image')}
                  >
                    <span className="hidden md:inline">プレビュー</span>
                  </Button>
                </td>
              </tr>
            })}
          </tbody>
        </Table>
      </div>
    </div>

    {selectedFile?.content_type.includes('pdf') && <iframe src={selectedFile?.preview_url} className="mt-8 px-[-32px] w-full h-[1000px] bg-gray-100" />}
    {selectedFile?.content_type.includes('image') && <img src={selectedFile?.preview_url} className="mt-8 px-[-32px] w-full object-contain bg-gray-100" />}
  </>
}

export default Show
