import { UserContext } from '@/components/Layout/Base'
import { MarketDatumModal } from '@/components/Page/MarketData/MarketDatumModal'
import { MarketDatumLinkModal } from '@/components/Page/MarketData/MarketDatumLinkModal'
import { PropertyDataModal } from '@/components/Page/DisplayItems/PropertyDataModal'
import { subMenuOptionsPricingType } from '@/models/menu'
import * as React from 'react'
import { useEffect } from 'react'
import { BuriedCulturaPropertyPartsModal } from '@/components/Page/Properties/Detail/Surrounding/BuriedCulturaPropertyPartsModal'
import { JreitPropertyPartsModal } from '@/components/Page/Properties/Detail/Surrounding/JreitPropertyPartsModal'
import {buriedCulturalPropertyLinks,  jReitMapLink, reitdbMAPLinks} from '@/models/constUrl'

type Props = {
  category?: string
  setSidebarClicked: (value: string) => void
  city: string
}

const Submenu: React.FC<Props> = ({ category, setSidebarClicked, city }) => {
  const { user } = React.useContext(UserContext)
  const [modalOpen, setModalOpen] = React.useState<boolean>(false)
  const [modalReportOpen, setModalReportOpen] = React.useState<boolean>(false)
  const [modalMarketDatumLinkOpen, setModalMarketDatumLinkOpen] = React.useState<boolean>(false)
  const [modalPropertyDataOpen, setPropertyDataOpen] = React.useState<boolean>(false)
  const [pathReport, setPathReport] = React.useState<string>('')
  const [queryReport, setQueryReport] = React.useState<string>('')
  const [isJreit, setJreit] = React.useState([null,null]);
  const [isBuriedCulturalAlertOpen, setBuriedCulturalAlertOpen] = React.useState(false)
  const [isJreitPropertyPartsModalAlertOpen, setJreitPropertyPartsModalAlertOpen] = React.useState(false)
  const [isJreitPropertyCategory, setJreitPropertyCategory] = React.useState("")
  const [property, setProperty] = React.useState(null);
  const middleModalLinkers = {
    buriedCultural: "埋蔵文化財包蔵地",
    jReitMapLink: "REIT.map",
    reitdbMAPLink: "REITDBMAP"
  };
  const closeBuriedCulturalAlertModal = () => setBuriedCulturalAlertOpen(false);
  const closeJreitPropertyPartsModalAlertOpen = () => setJreitPropertyPartsModalAlertOpen(false);



  const searchParams = new URLSearchParams(window.location.search)
  let analysesparams = ''

  if (
    searchParams.get('year_from') &&
    searchParams.get('month_from') &&
    searchParams.get('year_to') &&
    searchParams.get('month_to')
  ) {
    const year_from = searchParams.get('year_from')
    const month_from = searchParams.get('month_from')
    const year_to = searchParams.get('year_to')
    const month_to = searchParams.get('month_to')
    analysesparams = `&year_from=${year_from}&month_from=${month_from}&year_to=${year_to}&month_to=${month_to}`
  }

  const property_type = searchParams.get('property_type')
  const business_type_id = searchParams.get('business_type_id')

  if (property_type) {
    analysesparams = `${analysesparams}&property_type=${property_type}`
  }

  if (business_type_id) {
    analysesparams = `${analysesparams}&business_type_id=${business_type_id}`
  }

  const subMenuOptions = subMenuOptionsPricingType(user.pricing_type)
  const targetVolCheckArea = [
    '東京都千代田区',
    '東京都中央区',
    '東京都港区',
    '東京都新宿区',
    '東京都文京区',
    '東京都台東区',
    '東京都墨田区',
    '東京都江東区',
    '東京都品川区',
    '東京都目黒区',
    '東京都大田区',
    '東京都世田谷区',
    '東京都渋谷区',
    '東京都中野区',
    '東京都杉並区',
    '東京都豊島区',
    '東京都北区',
    '東京都荒川区',
    '東京都板橋区',
    '東京都練馬区',
    '東京都足立区',
    '東京都葛飾区',
    '東京都江戸川区',
  ]

  // 設定（リーダーと一般ユーザー）
  if (user.role === 'leader' || user.role === 'general') {
    subMenuOptions['settings'].menus = subMenuOptions['settings'].menus.filter((menu) => {
      if (user.pricing_type === 'owner') {
        return menu.title === 'ユーザー設定'
        // Bot開発完了までコメントアウト（スマホも対応する）
        // return menu.title === 'ユーザー設定' || menu.title === '部署設定'
      } else if (user.pricing_type === 'enterprise' || user.pricing_type === 'standard') {
        return menu.title === 'ユーザー設定' || menu.title === 'ファイル容量確認'
        // Bot開発完了までコメントアウト（スマホも対応する）
        /*
        return (
          menu.title === 'ユーザー設定' ||
          menu.title === 'ファイル容量確認' ||
          menu.title === '部署設定'
        )
        */
      } else {
        return menu.title === 'ユーザー設定' || menu.title === 'ファイル容量確認'
      }
    })
  }

  if (user.company.touki_enabled !== true && user.role !== 'system_admin') {
    subMenuOptions['properties'].menus = subMenuOptions['properties'].menus.filter(
      (menu) => menu.title !== '登記情報'
    )
  }

  if (
    (user.is_volume_check !== true && user.role !== 'system_admin') ||
    !targetVolCheckArea.includes(city)
  ) {
    subMenuOptions['properties'].menus = subMenuOptions['properties'].menus.filter(
      (menu) => menu.title !== 'Volume Check'
    )
  }

  if (user.role !== 'system_admin') {
    subMenuOptions['properties'].menus = subMenuOptions['properties'].menus.filter(
      (menu) => menu.title !== 'VolCheck時間帯設定'
    )
  }

  if (user.company.touki_enabled !== true && user.role !== 'system_admin') {
    subMenuOptions['settings'].menus = subMenuOptions['settings'].menus.filter(
      (menu) => menu.title !== '登記情報利用明細'
    )
  }

  if (user.company.ocr_enabled !== true && user.role !== 'system_admin') {
    subMenuOptions['settings'].menus = subMenuOptions['settings'].menus.filter(
      (menu) => menu.title !== 'OCR利用明細'
    )
  }

  // 物件管理
  if (category !== 'properties') {
    subMenuOptions['properties'].menus = subMenuOptions['properties'].menus.filter(
      (menu) => !['物件詳細', '関連資料', '周辺情報', 'Volume Check'].includes(menu.title)
    )
  }

  if (!category || !subMenuOptions[category]) {
    return null
  }
  const sidebarClicked = subMenuOptions[category].name
  useEffect(() => {
    setSidebarClicked(sidebarClicked)
  }, [category])
  const options: { title: string; path: string }[] = subMenuOptions?.[category]?.menus ?? []

  function accessLink(path) {
    const pathName = window.location.pathname
    const param = window.location.search
    if (path.includes('/to_time_frame')) {
      const property_id = pathName.includes('properties') ? pathName.split('/')[2] : new URLSearchParams(param).get('property_id')
      return path.replace('to_', '') + '?property_id=' + property_id
    } else if (!path.includes('/to_time_frame') && pathName.includes('/time_frame')) {
      const property_id = pathName.includes('properties') ? pathName.split('/')[2] : new URLSearchParams(param).get('property_id')
      return `/properties/${property_id}` + path
    } else if (pathName.includes('volume_check_requests/')) {
      return pathName.replace(/volume_check_requests\/.*/, '') + path
    } else if (param === '?tab=property_details') {
      return pathName.replace(/\/edit/, '') + path
    } else if (pathName.includes('proposals/')) {
      const property_id = new URLSearchParams(param).get('property_id')
      return pathName.replace(/proposals\/.*/, `properties/${property_id}`) + path
    } else if (pathName.includes('/property_documents')) {
      const property_id = new URLSearchParams(param).get('property_id')
      return pathName.replace(/property_documents\/.*/, `properties/${property_id}`) + path
    } else if (path.includes('analyses?')) {
      return path + analysesparams
    } else if (pathName.includes('touki_registration_requests/')) {
      return pathName.replace(/touki_registration_requests\/.*/, '') + path
    } else if (pathName.includes('property_public_files/')) {
      const property_id = new URLSearchParams(param).get('property_id')
      return pathName.replace(/property_public_files\/.*/, `properties/${property_id}`) + path
    } else {
      return path
    }
  }

  return (
    <div
      className="flex-none w-0 md:flex-none md:flex-col md:w-[178px] z-10
     bg-primary-light text-primary-font font-medium text-[14px] select-none"
    >
      <div className="hidden md:flex items-center pl-4 h-14">
        {React.createElement(subMenuOptions[category].icon)}&nbsp;
        <div>{subMenuOptions[category].name}</div>
      </div>
      <div className="hidden md:flex md:flex-col">
        {options.map((menu: { title: string; path: string }, index: React.Key) => {
          const pathName = window.location.pathname.match(/^\/\w+/)[0]
          const param = window.location.search
          return (
            <a
              key={index}
              href={accessLink(menu.path)}
              className={[
                'flex items-center px-6 py-2 hover:bg-white',
                (pathName === menu.path ||
                  `${pathName + param}`.includes(menu.path.substring(1)) || // ?tab=market_datum_report ⇒ tab=market_datum_report
                  `${pathName + param}`.includes(menu.path) ||
                  (window.location.pathname.includes('volume_check_requests') &&
                    menu.path.includes('volume_check')) ||
                  ((window.location.pathname.includes('property_documents') ||
                    param.includes('property_documents')) &&
                    menu.path.includes('market_datum_report')) ||
                  (window.location.pathname.includes('property_document_formats') &&
                    menu.path.includes('market_datum_report_layouts')) ||
                  (window.location.pathname.includes('property_public_files') &&
                    menu.path.includes('public_files')) ||
                  (window.location.pathname.includes('touki_registration_requests') &&
                    menu.path.includes('touki')) ||
                  (window.location.pathname.includes('/time_frame') &&
                    menu.path.includes('/to_time_frame'))) &&
                  'bg-white',
              ].join(' ')}
              onClick={(e) => {
                if (menu.title === '帳票フォーム設定') {
                  e.preventDefault()
                  setModalOpen(true)
                } else if (menu.title === '周辺情報') {
                  e.preventDefault()
                  const path = window.location.pathname.includes('/property_documents')
                  ? `/properties/${new URL(window.location.href).searchParams.get("property_id")}`
                  : window.location.pathname.includes('/time_frame')
                  ? `/properties/${new URL(window.location.href).searchParams.get("property_id")}`
                  : window.location.pathname;
                  setPathReport(path)
                  setQueryReport(menu.path.substring(1))
                  setModalMarketDatumLinkOpen(true)
                } else if (menu.title === '帳票作成') {
                  e.preventDefault()
                  const path = window.location.pathname.includes('/property_documents')
                  ? `/properties/${new URL(window.location.href).searchParams.get("property_id")}`
                  : window.location.pathname.includes('/time_frame')
                  ? `/properties/${new URL(window.location.href).searchParams.get("property_id")}`
                  : window.location.pathname;
                  setPathReport(path)
                  setQueryReport(menu.path.substring(1)) // ?tab=market_datum_report ⇒ tab=market_datum_report
                  setModalReportOpen(true)
                } else if(menu.title === '表示項目設定'){
                  e.preventDefault()
                  setPropertyDataOpen(true)
                }
              }}
            >
              <span className="pl-5">{menu.title}</span>
            </a>
          )
        })}
      </div>
      {/* 主要モーダル　s */}
      <PropertyDataModal
        open={modalPropertyDataOpen}
        onClose={() => setPropertyDataOpen(false)}
        path={pathPropertyData}
        title="物件種目"
      />
      <MarketDatumModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        path={pathLayout}
        title="帳票"
      />
      <MarketDatumModal
        open={modalReportOpen}
        onClose={() => setModalReportOpen(false)}
        path={pathReport}
        query={queryReport}
        title="帳票"
      />
      {modalMarketDatumLinkOpen && (
        <MarketDatumLinkModal
          open={modalMarketDatumLinkOpen}
          onClose={() => setModalMarketDatumLinkOpen(false)}
          path={pathReport}
          query={queryReport}
          title="周辺情報"
          onMarketDatumLink={modalMarketDatumLinkOpen}
          setProperty={setProperty}
          property={property}
          setJreit={setJreit}
          setBuriedCulturalAlertOpen={setBuriedCulturalAlertOpen}
          setJreitPropertyPartsModalAlertOpen={setJreitPropertyPartsModalAlertOpen}
          setJreitPropertyCategory={setJreitPropertyCategory}
          middleModalLinkers={middleModalLinkers}
        />
      )}
      {/* 主要モーダル　e */}
      {/* 中間モーダル　s */}
      {isBuriedCulturalAlertOpen && !pathReport.includes('analyses') && (
        <BuriedCulturaPropertyPartsModal
          title={middleModalLinkers.buriedCultural}
          isAlertOpen={isBuriedCulturalAlertOpen}
          closeAlertModal={closeBuriedCulturalAlertModal}
          buriedCulturalPropertyLinks={buriedCulturalPropertyLinks}
          property={property}
        />
      )}
      {isJreitPropertyPartsModalAlertOpen && !pathReport.includes('analyses') &&(
        <JreitPropertyPartsModal
          title={isJreitPropertyCategory === middleModalLinkers.jReitMapLink ? middleModalLinkers.jReitMapLink : isJreitPropertyCategory === middleModalLinkers.reitdbMAPLink ? middleModalLinkers.reitdbMAPLink : ""}
          isAlertOpen={isJreitPropertyPartsModalAlertOpen}
          closeAlertModal={closeJreitPropertyPartsModalAlertOpen}
          isJreitPropertyLinks={isJreitPropertyCategory === middleModalLinkers.jReitMapLink ? jReitMapLink() : isJreitPropertyCategory === middleModalLinkers.reitdbMAPLink ? reitdbMAPLinks() : ""}
          property={property}
          isJreit={isJreit}
          purpose={isJreitPropertyCategory === middleModalLinkers.jReitMapLink ? middleModalLinkers.jReitMapLink : isJreitPropertyCategory === middleModalLinkers.reitdbMAPLink ? middleModalLinkers.reitdbMAPLink : ""}
        />
      )}
      {/* 中間モーダル　e */}
    </div>
  )
}

const pathLayout = '/market_datum_report_layouts/new'
const pathPropertyData = '/display_items'

export default Submenu

