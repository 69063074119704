import { Button } from '@/components/Button'
import { Input } from '@/components/Input'
import { Select } from '@/components/Select'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from "@dnd-kit/utilities"
import DragIndicatorOutlined from '@material-ui/icons/DragIndicatorOutlined'
import * as React from 'react'

export interface CardProps {
  id: number
  index: number
  column_name_1: string
  column_name_2: string
  element_name: string
  column_list: { value: string; text: string }[]
  formatDetailData: string[]
  onChangeFormatDetailData: (value: string, index: number, column: string) => void
  onClickDeleteFormatDetailData: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => void
}

export const DetailCard: React.FC<CardProps> = ({
  id,
  index,
  column_name_1,
  column_name_2,
  element_name,
  column_list,
  formatDetailData,
  onChangeFormatDetailData,
  onClickDeleteFormatDetailData,
}) => {
  const { isDragging, setActivatorNodeRef, attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  return (
    <div
      key={id}
      ref={setNodeRef}
      className={`flex gap-[6px] bg-white ${isDragging ? "z-10" : ""}`}
      style={{
        transform: transform ? CSS.Transform.toString(transform) : undefined,
        transition: transition || undefined
      }}
    >
      <div className="flex gap-2">
        <Button
          //  cursor-${isDragging ? "grabbing" : "grab"}
          className={`text-primary-font ${isDragging ? "cursor-grabbing" : "cursor-grab"}`}
          prefix={<DragIndicatorOutlined fontSize="small" />}
          outline
          size="small"
          variant="primary"
          type="button"
          ref={setActivatorNodeRef}
          {...attributes}
          {...listeners}
        >
          <span className="text-primary-font hidden md:block">移動</span>
        </Button>
      </div>
      <div className="w-full">
        <Input
          value={column_name_1}
          name={`format_detail[${index}][column_name_1]`}
          id={`format_detail_${index}_column_name_1`}
          placeholder="(未設定)"
          onChange={(e) => onChangeFormatDetailData(e.target.value, index, 'column_name_1')}
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className="w-full">
        <Input
          value={column_name_2}
          name={`format_detail[${index}][column_name_2]`}
          id={`format_detail_${index}_column_name_2`}
          placeholder="(未設定)"
          onChange={(e) => onChangeFormatDetailData(e.target.value, index, 'column_name_2')}
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className="w-full">
        <Select
          className="text-sm"
          value={element_name}
          name={`format_detail[${index}][element_name]`}
          id={`format_detail_${index}_element_name`}
          options={column_list}
          onChange={(e) => onChangeFormatDetailData(e.target.value, index, 'element_name')}
        />
      </div>
      <input type="hidden" name={`format_detail[${index}][order]`} value={index + 1} />
      <div className="flex gap-2">
        {formatDetailData.length !== 1 && (
          <Button
            outline
            variant="red"
            size="small"
            onClick={(e) => onClickDeleteFormatDetailData(e, index)}
          >
            削除
          </Button>
        )}
      </div>
    </div>
  )
}
