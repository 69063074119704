import { Acquisition, Purchase, Rent } from '@/types/OCR'

import {
  zoningAreaCodes,
  areaOfUseCodes,
  fireProtectionCodes,
  altitudeAreaCodes,
  shadowAreaCodes,
} from '@/models/areaInfoCodeshashToArray'

// 法令制限の値をorでjoinし、ocr読み取り時の例文とする
const zoningAreaCodesText = zoningAreaCodes.map((code) => code.text).join(' or ')
const areaOfUseCodesText = areaOfUseCodes.map((code) => code.text).join(' or ')
const fireProtectionCodesText = fireProtectionCodes.map((code) => code.text).join(' or ')
const altitudeAreaCodesText = altitudeAreaCodes.map((code) => code.text).join(' or ')
const shadowAreaCodesText = shadowAreaCodes.map((code) => code.text).join(' or ')

export const exampleFormatAcquisition: Acquisition = {
  result: {
    住所: { 都道府県: '東京都', 市区町村: '港区', 町名: '北青山', '丁目・その他': '2-7-9' },
    地番: ['499-1,499-2,499-3,499-4'],
    物件名: '東京タワー',
    物件種目: '土地 or 土地＋建物（一棟）or 土地＋建物（区分）',
    土地: { 地積公簿: '100.00m2', 地積実測: '100.01m2' },
    現況: '更地 or 建物有（全空）or 建物有（稼働中） or 解体中 or 建築中',
    道路: [
      { 方位: '北', 幅員: '5.77m', 間口: '10.0m', 道路タイプ: '公道', 条項: '42条1項1号' },
      { 方位: '南', 幅員: '1.77m', 間口: '3.0m', 道路タイプ: '私道', 条項: '通路' },
      { 方位: '北西', 幅員: '34.77m', 間口: '3.0m', 道路タイプ: '公道', 条項: '42条1項2号' },
      { 方位: '南東', 幅員: '4.77m', 間口: '13.0m', 道路タイプ: '公道', 条項: '42条1項1号' },
    ],
    建物: {
      構造: 'rc or wrc or src or s_light or s_heavy or w or al or cft or cb or others',
      階数: { 地上: 5, 地下: 1 },
      築年月: '1990-06-01',
      総戸数: 10,
      延床面積: '1000.00m2',
      専有面積: '1000.00m2',
    },
    検査済証: '有',
    最寄り: [
      {
        路線名: '京王線',
        駅名: '京王線新宿',
        徒歩時間: '5分',
        バス時間: '',
        バス下車徒歩: '',
      },
      {
        路線名: '京王線',
        駅名: '京王線千歳烏山',
        徒歩時間: '',
        バス時間: '10分',
        バス下車徒歩: '5分',
      },
      {
        路線名: '南武線',
        駅名: '府中本町',
        徒歩時間: '20分',
        バス時間: '',
        バス下車徒歩: '',
      },
      {
        路線名: '小田急小田原線',
        駅名: '町田',
        徒歩時間: '25分',
        バス時間: '4分',
        バス下車徒歩: '3分',
      },
    ],
    IC: [
      {
        道路名: '中央自動車道',
        IC名: '八王子インターチェンジ',
        車距離: '5.0km',
        車時間: '14分',
      },
      {
        道路名: '名神高速道路',
        IC名: '蒲生スマートIC',
        車距離: '3.2km',
        車時間: '5分',
      },
      {
        道路名: '圏央道',
        IC名: '大栄ジャンクション',
        車距離: '1.５km',
        車時間: '９分',
      },
      {
        道路名: '首都高速湾岸線',
        IC名: '葛西JCT',
        車距離: '１０km',
        車時間: '１０分',
      },
      {
        道路名: '福岡高速6号アイランドシティ線',
        IC名: 'アイランドシティ出入口',
        車距離: '25.0km',
        車時間: '50分',
      },
      {
        道路名: '首都高速道路湾岸線',
        IC名: '舞浜入口',
        車距離: '',
        車時間: '20分',
      },
      {
        道路名: '阪神高速道路1号環状線',
        IC名: '北浜出口',
        車距離: '6.0km',
        車時間: '',
      },
      {
        道路名: '姫路バイパス',
        IC名: '市川ランプ',
        車距離: '34.2km',
        車時間: '120分',
      },
      {
        道路名: '山陰自動車道',
        IC名: '松江玉造本線料金所',
        車距離: '',
        車時間: '',
      },
    ],
    用途地域: [
      {
        区域区分: zoningAreaCodesText,
        種類: areaOfUseCodesText,
        防火地域: fireProtectionCodesText,
        高度地区: altitudeAreaCodesText,
        日影規制: shadowAreaCodesText,
        建蔽率: '80',
        容積率: '500',
        その他: 'その他',
      },
      {
        区域区分: zoningAreaCodesText,
        種類: areaOfUseCodesText,
        防火地域: fireProtectionCodesText,
        高度地区: altitudeAreaCodesText,
        日影規制: shadowAreaCodesText,
        建蔽率: '80',
        容積率: '500',
        その他: 'その他',
      },
      {
        区域区分: zoningAreaCodesText,
        種類: areaOfUseCodesText,
        防火地域: fireProtectionCodesText,
        高度地区: altitudeAreaCodesText,
        日影規制: shadowAreaCodesText,
        建蔽率: '80',
        容積率: '500',
        その他: 'その他',
      },
      {
        区域区分: zoningAreaCodesText,
        種類: areaOfUseCodesText,
        防火地域: fireProtectionCodesText,
        高度地区: altitudeAreaCodesText,
        日影規制: shadowAreaCodesText,
        建蔽率: '80',
        容積率: '500',
        その他: 'その他',
      },
    ],
    販売価格: '5000万円',
    希望価格: '5500万円',
    表面利回り: '5%',
    ネット利回り: '4%',
    '棟数（区画）': '5',
    月割り現況賃料: '1200000円',
    稼働率: '87%',
    賃料: {
      坪単価: '100000円',
      月割り現況賃料: '3200000円',
      月割り総額賃料: '1200000円',
      月割り想定NOI: '1000000円',
    },
    部屋番号: '205号室',
  },
}

export const exampleFormatRent: Rent = {
  result: {
    募集状態: '募集中 or 成約',
    住所: { 都道府県: '東京', 市区町村: '港区', 町名: '北青山', '丁目・その他': '2-7-9' },
    物件名: '東京タワー',
    間取り: '3LDK',
    最寄り: [
      {
        路線名: '京王線',
        駅名: '京王線新宿',
        徒歩時間: '5分',
        バス時間: '',
        バス下車徒歩: '',
      },
      {
        路線名: '京王線',
        駅名: '京王線千歳烏山',
        徒歩時間: '',
        バス時間: '10分',
        バス下車徒歩: '5分',
      },
      {
        路線名: '南武線',
        駅名: '府中本町',
        徒歩時間: '20分',
        バス時間: '',
        バス下車徒歩: '',
      },
      {
        路線名: '小田急小田原線',
        駅名: '町田',
        徒歩時間: '25分',
        バス時間: '4分',
        バス下車徒歩: '3分',
      },
    ],
    道路: [
      { 方位: '北', 幅員: '5.77m', 間口: '10.0m', 道路タイプ: '公道', 条項: '42条1項1号' },
      { 方位: '南', 幅員: '1.77m', 間口: '3.0m', 道路タイプ: '私道', 条項: '通路' },
      { 方位: '北西', 幅員: '34.77m', 間口: '3.0m', 道路タイプ: '公道', 条項: '42条1項2号' },
      { 方位: '南東', 幅員: '4.77m', 間口: '13.0m', 道路タイプ: '公道', 条項: '42条1項1号' },
    ],
    掲載日: '2023-10-01',
    成約日: '2023-10-02',
    建物: {
      構造: 'rc or wrc or src or s_light or s_heavy or w or al or cft or cb or others',
      階数: { 地上: 5, 地下: 1 },
      竣工年月: '1990-06-01',
      延床面積: '1000.00m2',
      専有面積: '1000.00m2',
    },
    賃料: {
      号室: '102',
      金額: '115000円',
      管理費: '10000円',
      敷金: '10000円',
      礼金: '0円',
    },
    向き: '南西',
    エレベータ: '有り or 無し',
    オートロック: '有り or 無し',
    バルコニー: '有り or 無し',
    ネット無料: '有り or 無し',
    家具家電: '有り or 無し',
    施工会社: '清水建設',
    物件タイプ: 'ビル',
    所在階: { 階層: '地上 or 地下', 階数: 4 },
    その他: { 特色: ['駅近物件', '築浅'], 注記: ['タバコ禁止'] },
    物件種目: '土地 or 土地＋建物（一棟）or 土地＋建物（区分）',
  },
}

export const exampleFormatPurchase: Purchase = {
  result: {
    募集状態: '募集中 or 成約',
    住所: { 都道府県: '東京', 市区町村: '港区', 町名: '北青山', '丁目・その他': '2-7-9' },
    物件名: '東京タワー',
    間取り: '3LDK',
    最寄り: [
      {
        路線名: '京王線',
        駅名: '京王線新宿',
        徒歩時間: '5分',
        バス時間: '',
        バス下車徒歩: '',
      },
      {
        路線名: '京王線',
        駅名: '京王線千歳烏山',
        徒歩時間: '',
        バス時間: '10分',
        バス下車徒歩: '5分',
      },
      {
        路線名: '南武線',
        駅名: '府中本町',
        徒歩時間: '20分',
        バス時間: '',
        バス下車徒歩: '',
      },
      {
        路線名: '小田急小田原線',
        駅名: '町田',
        徒歩時間: '25分',
        バス時間: '4分',
        バス下車徒歩: '3分',
      },
    ],
    道路: [
      { 方位: '北', 幅員: '5.77m', 間口: '10.0m', 道路タイプ: '公道', 条項: '42条1項1号' },
      { 方位: '南', 幅員: '1.77m', 間口: '3.0m', 道路タイプ: '私道', 条項: '通路' },
      { 方位: '北西', 幅員: '34.77m', 間口: '3.0m', 道路タイプ: '公道', 条項: '42条1項2号' },
      { 方位: '南東', 幅員: '4.77m', 間口: '13.0m', 道路タイプ: '公道', 条項: '42条1項1号' },
    ],
    掲載日: '2023-10-01',
    成約日: '2023-10-02',
    建物: {
      構造: 'rc or wrc or src or s_light or s_heavy or w or al or cft or cb or others',
      階数: { 地上: 5, 地下: 1 },
      竣工年月: '1990-06-01',
      総戸数: 10,
      延床面積: '1000.00m2',
      専有面積: '1000.00m2',
      敷地面積: '122.00m2',
      有効敷地面積: '110.00m2',
    },
    エレベータ: '有り or 無し',
    オートロック: '有り or 無し',
    成約: {
      成約価格: '130000000円',
      成約表面利回り: '3.57%',
    },
    売出: {
      売出価格: '150000000円',
      売出表面利回り: '8.54%',
    },
    賃料: {
      月割り現況賃料: '200000円',
      月割り満室想定賃料: '350000円',
    },
    施工会社: '清水建設',
    物件タイプ: 'ビル',
    所在階: { 階層: '地上 or 地下', 階数: 4 },
    その他: { 特色: ['駅近物件', '築浅'], 注記: ['タバコ禁止'] },
    物件種目: '土地 or 土地＋建物（一棟）or 土地＋建物（区分）',
  },
}

export function chooseFormat(formatType) {
  switch (formatType) {
    case 'acquisition':
      return exampleFormatAcquisition
      break
    case 'rent':
      return exampleFormatRent
      break
    case 'purchase':
      return exampleFormatPurchase
      break
    default:
      return undefined
  }
}
