import * as React from 'react'

import { Tab } from '@headlessui/react'
import { PropertyHazard } from './Surrounding/PropertyHazard'
import { BuriedCulturaPropertyModal } from './Surrounding/BuriedCulturaPropertyModal'
import { JreitPropertyModal } from './Surrounding/JreitPropertyModal'
import { PropertyMarketData } from './Surrounding/PropertyMarketData'
import { MapHandles } from '@/components/GoogleMapViewer'
import { Option, ChildOption } from '@/components/DropDown/Index'

import type { Property } from '@/types/property'
import { User } from '@/types/user'
import proj4 from 'proj4'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import { cover_enterprise_pricing_type } from '@/utils/policy'
import { buriedCulturalPropertyLinks, fixedAssetTaxStreetPriceLink, inheritanceTaxRoadValueLink, chikaKojiChosaLink, parcelSupportMapLink, jReitMapLink, reitdbMAPLinks } from '@/models/constUrl'


type TabType = 'for_sale' | 'for_rent' | 'hazard'

const PropetySurroundings: React.FC<{
  property: Property
  hazard_links?: { [key: string]: string }
  mapRef?: MapHandles
  user: User
  authenticity_token: string
  prefecture_options: Option[]
  transportation_options: Option[]
  station_options: ChildOption[]
  floor_plan_options: any[]
  contractor_options: any[]
  room_type_options: any[]
}> = ({
  property,
  hazard_links,
  mapRef,
  user,
  authenticity_token,
  prefecture_options,
  transportation_options,
  station_options,
  floor_plan_options,
  contractor_options,
  room_type_options,
}) => {
  const tabIndex = new URL(window.location.href).searchParams.get('tab_index')
  const defaultIndex = tabIndex ? Number(tabIndex) : 0
  const initialTab = tabFromIndex(user, defaultIndex)
  const tabIndexForSale = 0
  const tabIndexForRent = tabIndexForSale + 1

  const [tab, setTab] = React.useState<TabType>(initialTab)
  const [isOpen, setIsOpen] = React.useState(false)
  proj4.defs(
    'EPSG:4301',
    '+proj=longlat +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +no_defs +type=crs'
  )
  const WGS84 = 'EPSG:4326'

  const propertyLng = property?.lng
  const propertyLat = property?.lat

  const propertyCoord = [propertyLng, propertyLat]
  const pointInWGS84 = propertyCoord.map(parseFloat)

  let pointInTokyoDatum = null
  let lng = null
  let lat = null

  if (propertyLng !== null && propertyLat !== null) {
    if (pointInWGS84.every(Number.isFinite)) {
      pointInTokyoDatum = proj4(WGS84, 'EPSG:4301', pointInWGS84)
    } else {
      console.error('Invalid coordinates:', pointInWGS84)
    }

    if (pointInTokyoDatum) {
      lng = pointInTokyoDatum[0]
      lat = pointInTokyoDatum[1]
    } else {
      console.error('pointInTokyoDatum is null')
    }
  }

  const onChangeTab = (index: number) => {
    setTab(tabFromIndex(user, index))

    // タブ切り替え時に表示していた周辺事例のピンをクリアする
    if (mapRef?.current) {
      mapRef.current.clearMarketData()
    }
    // タブ切り替え時には検索条件を全てリセットする
    const searchParams = new URLSearchParams(window.location.search.replace(/\?.*$/, ''))
    searchParams.set('tab', 'surroundings')
    searchParams.set('tab_index', index.toString())
    const dataTypeName = index === 0 ? 'for_sale' : 'for_rent'
    searchParams.set('data_type', dataTypeName)
    location.search = searchParams.toString()
  }

  return (
    <div className="mt-10 mb-10">
      <Tab.Group defaultIndex={defaultIndex} onChange={onChangeTab}>
        <div className="flex text-sm overflow-x-auto whitespace-nowrap">
          <Tab.List className="flex items-center border-b-[1px] border-primary mb-2 md:w-full">
            {cover_enterprise_pricing_type(user) && (
              <Tab
                className={`${styles['tab']} ${
                  styles[tab === 'for_sale' ? 'true' : 'false']
                } border-l-[1px]`}
              >
                周辺売買事例
              </Tab>
            )}
            {cover_enterprise_pricing_type(user) && (
              <Tab className={`${styles['tab']} ${styles[tab === 'for_rent' ? 'true' : 'false']}`}>
                周辺賃貸事例
              </Tab>
            )}

            {lng !== null && lat !== null && (
              <>
                <div
                  className="inline-flex text-sm pt-[8px] py-[7px] px-2 bg-[#f4f5f6] shadow-[inset_2px_-2px_2px_rgba(0,0,0,0.1)] hover:bg-targetLine cursor-pointer"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <div className={isOpen ? 'text-primary' : 'text-targetLine-warning'}>
                    <KeyboardDoubleArrowRightIcon
                      fontSize="small"
                      style={{
                        transition: 'transform 0.1s ease-in-out',
                        transform: isOpen ? 'rotate(180deg)' : '',
                      }}
                    />
                  </div>
                </div>

                <div
                  className={`transition-opacity duration-200 ${
                    isOpen ? 'opacity-100 ' : 'opacity-0 '
                  }`}
                >
                  <a
                    href={`${fixedAssetTaxStreetPriceLink(lng, lat)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      style={{ display: isOpen ? 'inline-flex' : 'none' }}
                      className={`z-30 text-[#777] inline-flex px-6 md:px-8 py-[8px] mt-[1px] bg-[#f4f5f6] shadow-[inset_2px_-2px_2px_rgba(0,0,0,0.1)] select-none hover:bg-targetLine cursor-pointer`}
                    >
                      固定資産税路線価
                    </div>
                  </a>
                  <a
                    href={`${inheritanceTaxRoadValueLink(lng, lat)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      style={{ display: isOpen ? 'inline-flex' : 'none' }}
                      className={`z-20 text-[#777] inline-flex px-6 md:px-8 py-[8px] mt-[1px] bg-[#f4f5f6] shadow-[inset_2px_-2px_2px_rgba(0,0,0,0.1)] select-none hover:bg-targetLine cursor-pointer`}
                    >
                      相続税路線価
                    </div>
                  </a>
                  <a
                    href={`${chikaKojiChosaLink(lng, lat)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      style={{ display: isOpen ? 'inline-flex' : 'none' }}
                      className={`z-10 text-[#777] inline-flex px-6 md:px-8 py-[8px] mt-[1px] bg-[#f4f5f6] shadow-[inset_2px_-2px_2px_rgba(0,0,0,0.1)] select-none hover:bg-targetLine cursor-pointer`}
                    >
                      地価公示・地価調査
                    </div>
                  </a>

                  <Tab
                    className={`z-10 inline-flex px-6 md:px-8 py-[8px] mt-[1px] shadow-[inset_2px_-2px_2px_rgba(0,0,0,0.1)] select-none cursor-pointer ${
                      tab === 'hazard'
                        ? 'bg-primary text-white'
                        : 'text-[#777] bg-[#f4f5f6] hover:bg-targetLine'
                    }`}
                  >
                    ハザードマップ
                  </Tab>
                  <a
                    href={`${parcelSupportMapLink(lat,lng)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      style={{ display: isOpen ? 'inline-flex' : 'none' }}
                      className={`z-10 text-[#777] inline-flex px-6 md:px-8 py-[8px] mt-[1px] bg-[#f4f5f6] shadow-[inset_2px_-2px_2px_rgba(0,0,0,0.1)] select-none hover:bg-targetLine cursor-pointer`}
                    >
                      地盤サポートマップ
                    </div>
                  </a>
                  {buriedCulturalPropertyLinks(property?.prefecture,property?.city) && (
                    <BuriedCulturaPropertyModal
                      isOpen={isOpen}
                      property={property}
                      buriedCulturalPropertyLinks={buriedCulturalPropertyLinks}
                    />
                  )}
                  <JreitPropertyModal
                    isOpen={isOpen}
                    isJreitPropertyLinks={`${jReitMapLink()}`}
                    property={property}
                    purpose={"REIT.map"}
                  />
                  <JreitPropertyModal
                    isOpen={isOpen}
                    isJreitPropertyLinks={`${reitdbMAPLinks()}`}
                    property={property}
                    purpose={"REITDBMAP"}
                  />
                </div>
              </>
            )}
          </Tab.List>
        </div>
        <Tab.Panels>
          {cover_enterprise_pricing_type(user) && (
            <Tab.Panel unmount={false}>
              <PropertyMarketData
                property={property}
                data_type={'for_sale'}
                tabIndex={tabIndexForSale}
                tab={tab}
                mapRef={mapRef}
                current_user={user}
                authenticity_token={authenticity_token}
                prefecture_options={prefecture_options}
                transportation_options={transportation_options}
                station_options={station_options}
                floor_plan_options={floor_plan_options}
                contractor_options={contractor_options}
                room_type_options={room_type_options}
              />
            </Tab.Panel>
          )}
          {cover_enterprise_pricing_type(user) && (
            <Tab.Panel unmount={false}>
              <PropertyMarketData
                property={property}
                data_type={'for_rent'}
                tabIndex={tabIndexForRent}
                tab={tab}
                mapRef={mapRef}
                current_user={user}
                authenticity_token={authenticity_token}
                prefecture_options={prefecture_options}
                transportation_options={transportation_options}
                station_options={station_options}
                floor_plan_options={floor_plan_options}
                contractor_options={contractor_options}
                room_type_options={room_type_options}
              />
            </Tab.Panel>
          )}
          <Tab.Panel unmount={false}>
            <PropertyHazard hazard_links={hazard_links} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

const tabFromIndex = (user: User, index: number): TabType => {
  if (cover_enterprise_pricing_type(user)) {
    switch (index) {
      case 0:
        return 'for_sale'
      case 1:
        return 'for_rent'
      case 2:
        return 'hazard'
    }
  } else if (user.company.pricing_type === 'standard') {
    switch (index) {
      case 0:
        return 'hazard'
    }
  } else {
    switch (index) {
      case 0:
        return 'hazard'
    }
  }
}

const styles = {
  tab: `
    inline-flex
    outline-none
    select-none
    text-sm
    py-2
    px-8
    border-t-[1px]
    border-r-[1px]
    border-primary
  `,
  true: `
    bg-primary
    text-white
  `,
  false: `
    text-primary-font
    border-t-[1px]
    border-r-[1px]
    border-primary
  `,
}

export default PropetySurroundings
