import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Breadcrumb } from '@/components/Breadcrumb'
import type { User } from '@/types/user'
import { Table, Row, Th, Td } from '@/components/Table'
import { Input } from '@/components/Input'
import { Select } from '@/components/Select'
import { Checkbox } from '@/components/Checkbox'
import { DropDown, Option } from '@/components/DropDown/Index'
import { RadioButton } from '@/components/RadioButton'
import { Button, AnchorButton } from '@/components/Button'
import { prefectureOptions } from '@/models/prefecture'
import {
  toukiTargetOptions,
  getToukiTypeOptions,
  getToukiRequestOptions,
} from '@/models/toukiRecord'
import type { Property } from '@/types/property'
import Tippy from '@tippyjs/react'
import 'tippy.js/themes/light.css'
import { Loading } from '@/components/Loading'

type Props = {
  current_user: User
  property: Property
  authenticity_token?: string
  is_touki_account_setting: boolean
}

const ErrorPopup = ({ message, onClose }) => {
  React.useEffect(() => {
    const timer = setTimeout(() => {
      onClose()
    }, 5000)

    return () => clearTimeout(timer)
  }, [onClose])

  if (!message) {
    return null
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
        role="alert"
      >
        <strong className="font-bold">エラー：</strong>
        <span className="block sm:inline">{message}</span>
      </div>
    </div>
  )
}

const ToukiRecordEdit: React.FC<Props> = ({
  current_user,
  property,
  authenticity_token,
  is_touki_account_setting,
}) => {
  const title = '不動産登記新規取得'
  const [cityOptions, setCityOptions] = React.useState([])
  const [townOptions, setTownOptions] = React.useState([])
  const [toukiInfo, setToukiInfo] = React.useState({
    toukiTarget: 'land',
    prefecture: property?.prefecture || '',
    city: property?.city || '',
    town: property?.town || '',
    chome: property?.chome || '',
    propertyIdentifier: property?.chiban || '',
    toukiType: ['all'],
    jointGuarantee: 'no',
    trustRecord: 'no',
    closureRequest: false,
  })
  const {
    toukiTarget,
    prefecture,
    city,
    town,
    chome,
    propertyIdentifier,
    toukiType,
    jointGuarantee,
    trustRecord,
    closureRequest,
  } = toukiInfo
  const toukiTypeOptions = getToukiTypeOptions(toukiTarget, closureRequest)
  const csrfToken: HTMLMetaElement = document.head.querySelector('meta[name="csrf-token"]')
  const isTypeSelected = (typeValue: string) => toukiType.includes(typeValue)
  const [isTownInputEnabled, setIsTownInputEnabled] = React.useState(false)

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked
    setIsTownInputEnabled(isChecked)
    if (!isChecked) {
      setToukiInfo((prevState) => ({
        ...prevState,
        town: '',
        chome: '',
        propertyIdentifier: '',
      }))
    }
  }

  React.useEffect(() => {
    fetchCityOptions(prefecture, true, city, town)
  }, [])

  React.useEffect(() => {
    const newToukiTypeOptions = getToukiTypeOptions(toukiTarget, closureRequest)
    const newToukiTypeValues = newToukiTypeOptions.map((option) => option.value)
    const newToukiType = toukiInfo.toukiType.filter((value) => newToukiTypeValues.includes(value))

    if (closureRequest) {
      setToukiInfo((prevState) => ({
        ...prevState,
        toukiType: ['all'],
      }))
    } else {
      setToukiInfo((prevState) => ({
        ...prevState,
        toukiType: newToukiType,
      }))
    }
  }, [toukiTarget, closureRequest])

  React.useEffect(() => {
    if (closureRequest && (jointGuarantee === 'yes_current' || trustRecord === 'yes_current')) {
      setToukiInfo((prevState) => ({
        ...prevState,
        jointGuarantee: jointGuarantee === 'yes_current' ? 'no' : jointGuarantee,
        trustRecord: trustRecord === 'yes_current' ? 'no' : trustRecord,
      }))
    }
  }, [closureRequest, jointGuarantee, trustRecord])

  const formatChiban = (chibanData: string): string => {
    let filtered = chibanData.replace(/[^\d０-９-−ー－'、,]/g, '')

    filtered = filtered.replace(/[0-9]/g, (c) => {
      return String.fromCharCode(c.charCodeAt(0) + 0xfee0)
    })
    filtered = filtered.replace(/[-−ー]/g, '－')
    filtered = filtered.replace(/[、,]/g, ',')
    filtered = filtered.replace(/－+/g, '－')
    filtered = filtered.replace(/(^,|,$)/g, '')
    filtered = filtered.replace(/,－|－,/g, '－')
    return filtered
  }

  React.useEffect(() => {
    const existedChibanData = property?.chiban || ''
    const [newChome, newPropertyIdentifier] = extractChomeAndPropertyInfo(existedChibanData)
    setToukiInfo((prevState) => ({
      ...prevState,
      chome: newChome,
      propertyIdentifier: formatChiban(newPropertyIdentifier),
    }))
  }, [])

  React.useEffect(() => {
    if (!toukiType.includes('all')) {
      setToukiInfo((prevState) => ({
        ...prevState,
        jointGuarantee: 'no',
        trustRecord: 'no',
      }))
    }
  }, [toukiType])

  const convertToFullWidth = (str: string): string => {
    return str.replace(/[0-9]/g, (c) => {
      return String.fromCharCode(c.charCodeAt(0) + 0xfee0)
    })
  }

  const extractChomeAndPropertyInfo = (chibanData: string): [string, string] => {
    const entries = chibanData.split(', ')
    let chomeSet = new Set()
    let propertyIdentifierList = []
    let hasChome = false

    for (const entry of entries) {
      const parts = entry.split('-').map((part) => convertToFullWidth(part))

      if (parts.length === 3) {
        chomeSet.add(`${parts[0]}丁目`)
        propertyIdentifierList.push(`${parts[1]}-${parts[2]}`)
        hasChome = true
      } else {
        propertyIdentifierList.push(convertToFullWidth(entry))
      }
    }

    if (!hasChome) {
      chomeSet.add('大字なし')
    }

    const newChome = Array.from(chomeSet).join(', ')
    const newPropertyIdentifier = propertyIdentifierList.join(', ')

    return [newChome, newPropertyIdentifier]
  }

  const fetchCityOptions = (
    prefecture,
    isInit = false,
    cityInitial?: string,
    townInitial?: string
  ) => {
    if (prefecture === '') {
      return
    }

    fetch('/japanese-addresses/ja.json')
      .then((response) => response.json())
      .then((json) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const options = json[prefecture].map((cityName) => {
          return { value: cityName, label: cityName }
        })

        setCityOptions(options)
      })
      .catch(() => {
        setCityOptions([])
        setTownOptions([])
      })
      .finally(() => {
        if (prefecture !== '') {
          if (isInit && cityInitial) {
            setToukiInfo((prevState) => ({
              ...prevState,
              city: cityInitial,
            }))
            fetchTownOptions(prefecture, cityInitial, townInitial)
          }
        }
        setToukiInfo((prevState) => ({
          ...prevState,
          town: '',
        }))
      })
  }

  const fetchTownOptions = (prefecture: string, city: string, townInitial?: string) => {
    if (city === '') {
      return
    }

    const uri = `/japanese-addresses/ja/${prefecture}/${city}.json`
    fetch(uri)
      .then((response) => response.json())
      .then((json) => {
        if (!json) {
          setToukiInfo((prevState) => ({
            ...prevState,
            town: '',
          }))
          setTownOptions([])
          return
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
        const options = json.map((data) => ({
          value: data.town,
          label: data.town,
        }))
        setTownOptions(options)
      })
      .catch(() => {
        setTownOptions([])
      })
      .finally(() => {
        if (prefecture !== '' && city !== '') {
          if (townInitial) {
            setToukiInfo((prevState) => ({
              ...prevState,
              town: townInitial,
            }))
          }
        }
      })
  }

  const onChangePrefecture = (newPrefecture) => {
    setToukiInfo((prevState) => ({
      ...prevState,
      prefecture: newPrefecture,
      city: '',
      town: '',
      chome: '',
      propertyIdentifier: '',
    }))
    setCityOptions([])
    setTownOptions([])
    fetchCityOptions(newPrefecture)
  }

  const onChangeCity = (newCity) => {
    setToukiInfo((prevState) => ({
      ...prevState,
      city: newCity,
      town: '',
      chome: '',
      propertyIdentifier: '',
    }))
    setTownOptions([])
    fetchTownOptions(prefecture, newCity)
  }

  const formatChome = (value: string): string => {
    const allowedCharacters = value.match(/[0-9０-９一二三四五六七八九十丁目大字なし]/g)
    let extracted = allowedCharacters === null ? '' : allowedCharacters.join('')
    extracted = extracted.replace(/[0-9]/g, (c) => {
      return String.fromCharCode(c.charCodeAt(0) + 0xfee0)
    })
    if (!extracted.includes('丁目') && !extracted.includes('大字なし') && extracted !== '') {
      extracted += '丁目'
    }
    return extracted
  }

  const [landDiagramData, setLandDiagramData] = React.useState(null)
  const [easementData, setEasementData] = React.useState(null)
  const [buildingFloorData, setBuildingFloorData] = React.useState(null)

  const loadingIcon = <Loading height={12} width={12} />
  const [loadingType, setLoadingType] = React.useState(null)

  const [expandedRowLand, setExpandedRowLand] = React.useState(null)
  const [selectedRowsLand, setSelectedRowsLand] = React.useState({})
  const handleRowClickLand = (chibanAddress, dataItem, index) => {
    setSelectedRowsLand((prevRows) => ({
      ...prevRows,
      [chibanAddress]: dataItem,
    }))
    setExpandedRowLand(null)
  }

  const [expandedRowEasement, setExpandedRowEasement] = React.useState(null)
  const [selectedRowsEasement, setSelectedRowsEasement] = React.useState({})
  const handleRowClickEasement = (chibanAddress, dataItem, index) => {
    setSelectedRowsEasement((prevRows) => ({
      ...prevRows,
      [chibanAddress]: dataItem,
    }))
    setExpandedRowEasement(null)
  }

  const [expandedRowBuilding, setExpandedRowBuilding] = React.useState(null)
  const [selectedRowsBuilding, setSelectedRowsBuilding] = React.useState({})
  const handleRowClickBuilding = (chiban, dataItem, index) => {
    setSelectedRowsBuilding((prevRows) => ({
      ...prevRows,
      [chiban]: dataItem,
    }))
    setExpandedRowBuilding(null)
  }

  const [errorMessage, setErrorMessage] = React.useState(null)
  const glistCheck = (checkType: string) => {
    setLoadingType(checkType)
    fetch('/glist_check_request', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken.content,
      },
      body: JSON.stringify({
        toukiTarget: toukiInfo.toukiTarget,
        prefecture: toukiInfo.prefecture,
        city: toukiInfo.city,
        town: toukiInfo.town,
        chome: toukiInfo.chome,
        chiban: toukiInfo.propertyIdentifier,
        checkType: checkType,
      }),
    })
      .then((response) => {
        return response.json()
      })
      .then((data) => {
        if (data.status === 'ok') {
          setLoadingType(null)
          switch (checkType) {
            case 'land_diagram':
              setLandDiagramData(data.results)
              break
            case 'easement':
              setEasementData(data.results)
              break
            case 'building_floor':
              setBuildingFloorData(data.results)
              break
            default:
              console.error('Unknown checkType')
              setErrorMessage('取得種類が不明です。')
              break
          }
        } else {
          setLoadingType(null)
          console.error(data.message)
          setErrorMessage(data.message)
        }
      })
      .catch((error) => {
        setLoadingType(null)
        console.error('Error:', error)
        setErrorMessage('処理中に問題が発生しました。再度お試しください。')
      })
      .finally(() => {
        setLoadingType(null)
      })
  }

  const formRef = React.useRef(null)
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false)
  const handleButtonClick = (event) => {
    event.preventDefault()
    setShowConfirmDialog(true)
  }

  const handleConfirm = (confirm) => {
    setShowConfirmDialog(false)
    if (confirm) {
      formRef.current && formRef.current.submit()
    } else {
      return
    }
  }

  const checkDisableAction = (type) => {
    if (type === 'zumenList') {
      return (
        !is_touki_account_setting ||
        loadingType !== null ||
        prefecture === '' ||
        city === '' ||
        town === '' ||
        propertyIdentifier === ''
      )
    } else if (type === 'submit') {
      return (
        !is_touki_account_setting ||
        prefecture === '' ||
        city === '' ||
        town === '' ||
        propertyIdentifier === '' ||
        toukiType.length === 0
      )
    }
  }
  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={['登記情報', '新規取得']} />}
      city={property.prefecture + property.city}
      submenuCategory={'properties'}
    >
      <ErrorPopup message={errorMessage} onClose={() => setErrorMessage(null)} />
      <form
        ref={formRef}
        className="mt-8"
        action={`/properties/${property.hashid}/touki_registration_requests`}
        acceptCharset="UTF-8"
        method="post"
      >
        <input type="hidden" name="_method" value={'post'} />
        <input type="hidden" name="authenticity_token" value={authenticity_token} />
        <input type="hidden" name="touki[land_diagram]" value={JSON.stringify(selectedRowsLand)} />
        <input type="hidden" name="touki[easement]" value={JSON.stringify(selectedRowsEasement)} />
        <input
          type="hidden"
          name="touki[building_floor]"
          value={JSON.stringify(selectedRowsBuilding)}
        />
        <div className='mb-[130px]'>
          <Table
            className="block mb-16 overflow-x-visible"
            header={
              <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
                所在指定
              </div>
            }
          >
            <tbody className="block md:table-row-group">
              <Row label={<Th required>対象</Th>}>
                <Td>
                  <div className="flex gap-8">
                    {toukiTargetOptions.map((item, i) => (
                      <RadioButton
                        key={i}
                        name="touki[target]"
                        id={`touki_target_${i}`}
                        text={item.text}
                        value={item.value}
                        checked={toukiTarget === item.value}
                        disabled={false}
                        onChange={(e) =>
                          setToukiInfo((prevState) => ({
                            ...prevState,
                            toukiTarget: e.target.value,
                          }))
                        }
                      />
                    ))}
                  </div>
                </Td>
              </Row>
              <Row label={<Th required>都道府県</Th>}>
                <Td>
                  <div className="flex gap-4 flex-col md:flex-row">
                    <DropDown
                      className="md:w-[300px] inline-block"
                      name="touki[prefecture]"
                      id="touki_prefecture"
                      placeholder="---"
                      options={prefectureOptions}
                      value={prefecture && { value: prefecture, label: prefecture }}
                      onChange={(e) => {
                        onChangePrefecture((e as Option)?.value || '')
                      }}
                    />
                  </div>
                </Td>
              </Row>
              {/* 市区町村取得 */}
              <Row label={<Th required>市区町村</Th>}>
                <Td>
                  <div className="flex gap-4 flex-col md:flex-row">
                    <DropDown
                      className="md:w-[300px] inline-block"
                      name="touki[city]"
                      id="touki_city"
                      placeholder="---"
                      options={cityOptions}
                      value={city && { value: city, label: city }}
                      onChange={(e) => {
                        onChangeCity((e as Option)?.value || '')
                      }}
                    />
                  </div>
                </Td>
              </Row>
              {/* 町名取得 */}
              <Row label={<Th required>町名</Th>}>
                <Td>
                  <div className="flex gap-4 flex-col md:flex-row items-center">
                    {isTownInputEnabled ? (
                      <Input
                        className="md:w-[300px] inline-block"
                        value={town}
                        name="touki[town]"
                        id="touki_town"
                        onChange={(e) => {
                          setToukiInfo((prevState) => ({
                            ...prevState,
                            town: e.target.value,
                          }))
                        }}
                      />
                    ) : (
                      <DropDown
                        className="md:w-[300px] inline-block"
                        name="touki[town]"
                        id="touki_town"
                        placeholder="---"
                        options={townOptions}
                        value={
                          townOptions.map((e) => e.label).includes(town) && {
                            label: town,
                            value: town,
                          }
                        }
                        onChange={(e) => {
                          setToukiInfo((prevState) => ({
                            ...prevState,
                            town: (e as Option)?.label || '',
                            chome: '',
                            propertyIdentifier: '',
                          }))
                        }}
                      />
                    )}
                    <Checkbox
                      id="touki_town_input"
                      text="手入力に切替"
                      checked={isTownInputEnabled}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </Td>
              </Row>
              <Row label={<Th>丁目</Th>}>
                <Td>
                  <div className="flex gap-4 flex-col md:flex-row">
                    <Input
                      className="md:w-[300px] inline-block"
                      value={chome}
                      name="touki[chome]"
                      id="touki_chome"
                      onChange={(e) => {
                        setToukiInfo((prevState) => ({
                          ...prevState,
                          chome: e.target.value,
                        }))
                      }}
                      onBlur={(e) => {
                        const value = formatChome(e.target.value)
                        setToukiInfo((prevState) => ({
                          ...prevState,
                          chome: value,
                        }))
                      }}
                    />
                  </div>
                </Td>
              </Row>
              <Row label={<Th required>地番・家屋番号</Th>}>
                <Td>
                  <div className="flex gap-4 flex-col md:flex-row">
                    <Input
                      className="md:w-[300px] inline-flex"
                      value={propertyIdentifier}
                      name="touki[chiban]"
                      id="touki_chiban"
                      placeholder="356-7, 569-9"
                      onChange={(e) => {
                        setToukiInfo((prevState) => ({
                          ...prevState,
                          propertyIdentifier: e.target.value,
                        }))
                      }}
                      onBlur={(e) => {
                        const value = formatChiban(e.target.value)
                        setToukiInfo((prevState) => ({
                          ...prevState,
                          propertyIdentifier: value,
                        }))
                      }}
                    />
                  </div>
                </Td>
              </Row>
            </tbody>
          </Table>
          <Table
            className="block mb-16 overflow-x-visible"
            header={
              <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
                取得内容選択
              </div>
            }
          >
            <tbody className="block md:table-row-group">
              <Row label={<Th required>種類</Th>}>
                <Td>
                  <Select
                    multiple
                    className="md:w-[300px]"
                    name="touki[type_option][]"
                    id="touki_type_option"
                    value={toukiType}
                    options={toukiTypeOptions}
                    onChange={(e) => {
                      const values = Array.from(e.target.selectedOptions, (option) => option.value)
                      setToukiInfo((prevState) => ({
                        ...prevState,
                        toukiType: values,
                      }))
                    }}
                  />
                  <span className="hidden md:block md:text-xs px-4 pt-1 pb-2 whitespace-normal text-primary font-small">
                    ※ Ctrlキーを押しながらクリックすることで、選択解除や複数選択が可能です。
                  </span>
                </Td>
              </Row>
              {isTypeSelected('land_diagram') && (
                <Row
                  label={
                    <Th required>
                      土地所在地／地積測量図
                      {landDiagramData && (
                        <span
                          className={`ml-2 inline-block py-0.5 px-0.5 leading-none text-[10px] border ${
                            !is_touki_account_setting ? 'border-[#d4c5c5]' : 'border-primary'
                          }`}
                        >
                          <Button
                            className="w-[82px] self-center"
                            size="tiny"
                            type="button"
                            disabled={checkDisableAction('zumenList')}
                            onClick={() => glistCheck('land_diagram')}
                          >
                            {loadingType === 'land_diagram' ? loadingIcon : '図面再取得'}
                          </Button>
                        </span>
                      )}
                    </Th>
                  }
                >
                  <Td>
                    {landDiagramData ? (
                      <div className="nested-table">
                        <table>
                          <thead>
                            <tr>
                              <th>所在及び地番・家屋番号 / 不動産番号</th>
                              <th>閉鎖 / 一覧</th>
                            </tr>
                          </thead>
                          <tbody>
                            {landDiagramData.map((item, index) => (
                              <>
                                <tr key={index}>
                                  <td>{item.chibanAddress}</td>
                                  <td>
                                    <span
                                      className="cursor-pointer border-2 border-primary-font text-primary-font p-1 inline-block"
                                      onClick={() =>
                                        setExpandedRowLand(expandedRowLand === index ? null : index)
                                      }
                                    >
                                      {expandedRowLand === index ? '閉じる' : '図面一覧'}
                                    </span>
                                    {selectedRowsLand[item.chibanAddress] ? (
                                      <span> 済✓ </span>
                                    ) : null}
                                  </td>
                                </tr>
                                {expandedRowLand === index && (
                                  <tr>
                                    <td colSpan={4}>
                                      <div>
                                        <table>
                                          <thead>
                                            <tr>
                                              <th>登記年月日</th>
                                              <th>事件ID</th>
                                              <th>事件前物件</th>
                                              <th>事件後物件</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {item.data.length > 0 ? (
                                              item.data.map((dataItem, dataIndex) => (
                                                <tr
                                                  key={dataIndex}
                                                  className="hover:bg-gray-100 cursor-pointer"
                                                  onClick={() =>
                                                    handleRowClickLand(
                                                      item.chibanAddress,
                                                      dataItem,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <td>{dataItem.zumenRegistDate}</td>
                                                  <td>{dataItem.zumenJikenId}</td>
                                                  <td>{dataItem.shozaiJikenMae}</td>
                                                  <td>{dataItem.shozaiJikenAto}</td>
                                                </tr>
                                              ))
                                            ) : (
                                              <tr>
                                                <td colSpan={4} className="text-center text-red">
                                                  ・{item.errorMsg}
                                                  <br />・{item.errorCode}
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <span
                        className={`ml-2 inline-block py-0.5 px-0.5 leading-none text-[10px] border ${
                          !is_touki_account_setting ? 'border-[#d4c5c5]' : 'border-primary'
                        }`}
                      >
                        <Button
                          className="w-[82px] self-center"
                          size="tiny"
                          type="button"
                          disabled={checkDisableAction('zumenList')}
                          onClick={() => glistCheck('land_diagram')}
                        >
                          {loadingType === 'land_diagram' ? loadingIcon : '図面取得'}
                        </Button>
                      </span>
                    )}
                  </Td>
                </Row>
              )}
              {isTypeSelected('easement') && (
                <Row
                  label={
                    <Th required>
                      地役権図面
                      {easementData && (
                        <span
                          className={`ml-2 inline-block py-0.5 px-0.5 leading-none text-[10px] border ${
                            !is_touki_account_setting ? 'border-[#d4c5c5]' : 'border-primary'
                          }`}
                        >
                          <Button
                            className="w-[82px] self-center"
                            size="tiny"
                            type="button"
                            disabled={checkDisableAction('zumenList')}
                            onClick={() => glistCheck('easement')}
                          >
                            {loadingType === 'easement' ? loadingIcon : '図面再取得'}
                          </Button>
                        </span>
                      )}
                    </Th>
                  }
                >
                  <Td>
                    {easementData ? (
                      <div className="nested-table">
                        <table>
                          <thead>
                            <tr>
                              <th>所在及び地番・家屋番号 / 不動産番号</th>
                              <th>閉鎖 / 一覧</th>
                            </tr>
                          </thead>
                          <tbody>
                            {easementData.map((item, index) => (
                              <>
                                <tr key={index}>
                                  <td>{item.chibanAddress}</td>
                                  <td>
                                    <span
                                      className="cursor-pointer border-2 border-primary-font text-primary-font p-1 inline-block"
                                      onClick={() =>
                                        setExpandedRowEasement(
                                          expandedRowEasement === index ? null : index
                                        )
                                      }
                                    >
                                      {expandedRowEasement === index ? '閉じる' : '図面一覧'}
                                    </span>
                                    {selectedRowsEasement[item.chibanAddress] ? (
                                      <span> 済✓ </span>
                                    ) : null}
                                  </td>
                                </tr>
                                {expandedRowEasement === index && (
                                  <tr>
                                    <td colSpan={4}>
                                      <div>
                                        <table>
                                          <thead>
                                            <tr>
                                              <th>登記年月日</th>
                                              <th>事件ID</th>
                                              <th>事件前物件</th>
                                              <th>事件後物件</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {item.data.length > 0 ? (
                                              item.data.map((dataItem, dataIndex) => (
                                                <tr
                                                  key={dataIndex}
                                                  className="hover:bg-gray-100 cursor-pointer"
                                                  onClick={() =>
                                                    handleRowClickEasement(
                                                      item.chibanAddress,
                                                      dataItem,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <td>{dataItem.zumenRegistDate}</td>
                                                  <td>{dataItem.zumenJikenId}</td>
                                                  <td>{dataItem.shozaiJikenMae}</td>
                                                  <td>{dataItem.shozaiJikenAto}</td>
                                                </tr>
                                              ))
                                            ) : (
                                              <tr>
                                                <td colSpan={4} className="text-center text-red">
                                                  ・{item.errorMsg}
                                                  <br />・{item.errorCode}
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <span
                        className={`ml-2 inline-block py-0.5 px-0.5 leading-none text-[10px] border ${
                          !is_touki_account_setting ? 'border-[#d4c5c5]' : 'border-primary'
                        }`}
                      >
                        <Button
                          className="w-[82px] self-center"
                          size="tiny"
                          type="button"
                          disabled={checkDisableAction('zumenList')}
                          onClick={() => glistCheck('easement')}
                        >
                          {loadingType === 'easement' ? loadingIcon : '図面取得'}
                        </Button>
                      </span>
                    )}
                  </Td>
                </Row>
              )}
              {isTypeSelected('building_floor') && (
                <Row
                  label={
                    <Th required>
                      建物図面／各階平面図
                      {buildingFloorData && (
                        <span
                          className={`ml-2 inline-block py-0.5 px-0.5 leading-none text-[10px] border ${
                            !is_touki_account_setting ? 'border-[#d4c5c5]' : 'border-primary'
                          }`}
                        >
                          <Button
                            className="w-[82px] self-center"
                            size="tiny"
                            type="button"
                            disabled={checkDisableAction('zumenList')}
                            onClick={() => glistCheck('building_floor')}
                          >
                            {loadingType === 'building_floor' ? loadingIcon : '図面再取得'}
                          </Button>
                        </span>
                      )}
                    </Th>
                  }
                >
                  <Td>
                    {buildingFloorData ? (
                      <div className="nested-table">
                        <table>
                          <thead>
                            <tr>
                              <th>所在及び地番・家屋番号 / 不動産番号</th>
                              <th>閉鎖 / 一覧</th>
                            </tr>
                          </thead>
                          <tbody>
                            {buildingFloorData.map((item, index) => (
                              <>
                                <tr key={index}>
                                  <td>{item.chibanAddress}</td>
                                  <td>
                                    <span
                                      className="cursor-pointer border-2 border-primary-font text-primary-font p-1 inline-block"
                                      onClick={() =>
                                        setExpandedRowBuilding(
                                          expandedRowBuilding === index ? null : index
                                        )
                                      }
                                    >
                                      {expandedRowBuilding === index ? '閉じる' : '図面一覧'}
                                    </span>
                                    {selectedRowsBuilding[item.chibanAddress] ? (
                                      <span> 済✓ </span>
                                    ) : null}
                                  </td>
                                </tr>
                                {expandedRowBuilding === index && (
                                  <tr>
                                    <td colSpan={4}>
                                      <div>
                                        <table>
                                          <thead>
                                            <tr>
                                              <th>登記年月日</th>
                                              <th>事件ID</th>
                                              <th>事件前物件</th>
                                              <th>事件後物件</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {item.data.length > 0 ? (
                                              item.data.map((dataItem, dataIndex) => (
                                                <tr
                                                  key={dataIndex}
                                                  className="hover:bg-gray-100 cursor-pointer"
                                                  onClick={() =>
                                                    handleRowClickBuilding(
                                                      item.chibanAddress,
                                                      dataItem,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <td>{dataItem.zumenRegistDate}</td>
                                                  <td>{dataItem.zumenJikenId}</td>
                                                  <td>{dataItem.shozaiJikenMae}</td>
                                                  <td>{dataItem.shozaiJikenAto}</td>
                                                </tr>
                                              ))
                                            ) : (
                                              <tr>
                                                <td colSpan={4} className="text-center text-red">
                                                  ・{item.errorMsg}
                                                  <br />・{item.errorCode}
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <span
                        className={`ml-2 inline-block py-0.5 px-0.5 leading-none text-[10px] border ${
                          !is_touki_account_setting ? 'border-[#d4c5c5]' : 'border-primary'
                        }`}
                      >
                        <Button
                          className="w-[82px] self-center"
                          size="tiny"
                          type="button"
                          disabled={checkDisableAction('zumenList')}
                          onClick={() => glistCheck('building_floor')}
                        >
                          {loadingType === 'building_floor' ? loadingIcon : '図面取得'}
                        </Button>
                      </span>
                    )}
                  </Td>
                </Row>
              )}
              <Row label={<Th required>共同担保選択</Th>}>
                <Td>
                  <div className="flex gap-8">
                    {getToukiRequestOptions(closureRequest).map((item, i) => (
                      <RadioButton
                        key={i}
                        name="touki[joint_guarantee]"
                        id={`touki_joint_guarantee_${i}`}
                        text={item.text}
                        value={item.value}
                        checked={jointGuarantee === item.value}
                        disabled={!toukiType.includes('all')}
                        onChange={(e) =>
                          setToukiInfo((prevState) => ({
                            ...prevState,
                            jointGuarantee: e.target.value,
                          }))
                        }
                      />
                    ))}
                  </div>
                </Td>
              </Row>
              <Row label={<Th required>信託目録</Th>}>
                <Td>
                  <div className="flex gap-8">
                    {getToukiRequestOptions(closureRequest).map((item, i) => (
                      <RadioButton
                        key={i}
                        name="touki[trust_record]"
                        id={`touki_trust_record_${i}`}
                        text={item.text}
                        value={item.value}
                        checked={trustRecord === item.value}
                        disabled={!toukiType.includes('all')}
                        onChange={(e) =>
                          setToukiInfo((prevState) => ({
                            ...prevState,
                            trustRecord: e.target.value,
                          }))
                        }
                      />
                    ))}
                  </div>
                </Td>
              </Row>
              <Row label={<Th>閉鎖登記簿</Th> }>
                <Td>
                  <div className="flex gap-8">
                    <Checkbox
                      name="touki[closure_request]"
                      id="touki_closure_request"
                      text="閉鎖登記簿請求"
                      checked={closureRequest}
                      onChange={(e) =>
                        setToukiInfo((prevState) => ({
                          ...prevState,
                          closureRequest: e.target.checked,
                        }))
                      }
                    />
                  </div>
                </Td>
              </Row>
            </tbody>
          </Table>
        </div>
        <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-150 md:py-2 fixed bottom-0 md:left-12 z-20">
          <AnchorButton
            className="w-full md:w-40 md:h-auto text-primary-font border-white"
            outline
            variant="text"
            href={`/properties/${property.hashid}/touki_registration_requests/new`}
          >
            キャンセル
          </AnchorButton>
          <Tippy
            theme="light"
            content={
              !is_touki_account_setting
                ? '登記情報提供サービスのIDまたはパスワードが設定されていません。アカウント設定を確認してください。'
                : checkDisableAction('submit')
                ? '必須項目を設定してください。'
                : ''
            }
            visible={checkDisableAction('submit')}
            className="p-2 rounded mb-4"
            placement="top"
          >
            <span></span>
          </Tippy>
          <Button
            className="w-full md:w-40 md:h-auto"
            disabled={checkDisableAction('submit')}
            onClick={handleButtonClick}
          >
            取得
          </Button>
        </div>

        {showConfirmDialog && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div
              className="bg-white border border-primary text-gray-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <p className="mb-4">登記情報の取得が成功した場合、料金が発生します。取得しますか？</p>
              <div className="flex justify-end space-x-6">
                <button
                  onClick={() => handleConfirm(false)}
                  className="text-primary-font px-4 py-1 hover:text-primary transition-colors"
                >
                  キャンセル
                </button>
                <button
                  onClick={() => handleConfirm(true)}
                  className="bg-primary border border-primary text-white rounded-sm px-10 py-1 hover:bg-[#4998c4] transition-colors"
                >
                  はい
                </button>
              </div>
            </div>
          </div>
        )}
      </form>
    </Base>
  )
}

export default ToukiRecordEdit
