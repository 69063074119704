import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Button, AnchorButton } from '@/components/Button'
import ClearIcon from '@material-ui/icons/Clear'
import { BuriedCulturaPropertyPartsModal } from '@/components/Page/Properties/Detail/Surrounding/BuriedCulturaPropertyPartsModal'



type Props = {
  isOpen: boolean;
  buriedCulturalPropertyLinks?: any;
  property:any;
}


export const BuriedCulturaPropertyModal: React.FC<Props> = ({
    isOpen = false,
    buriedCulturalPropertyLinks,
    property,
}) => {
  const [isAlertOpen, setAlertOpen] = React.useState(false)
  const title = "埋蔵文化財包蔵地"
  const closeAlertModal = () => {
    setAlertOpen(false);
  };

  const openAlertModal = () => {
    setAlertOpen(true);
  };

  // 以下のコメントアウトはDebugように残しておいてください
  console.log(property?.prefecture,property?.city)


  return (
    <>
      <div
        style={{ display: isOpen ? 'inline-flex' : 'none' }}
        className={`z-10 text-[#777] inline-flex px-6 md:px-8 py-[8px] mt-[1px] bg-[#f4f5f6] shadow-[inset_2px_-2px_2px_rgba(0,0,0,0.1)] select-none hover:bg-targetLine cursor-pointer`}
        onClick={openAlertModal}
      >
        {title}
      </div>

      <BuriedCulturaPropertyPartsModal
        title={title}
        isAlertOpen={isAlertOpen}
        closeAlertModal={closeAlertModal}
        buriedCulturalPropertyLinks={buriedCulturalPropertyLinks}
        property={property}
      />

    </>
  )
}

export default BuriedCulturaPropertyModal
