import '@/components/GoogleMapViewer/style.css'
import type { LatLng } from '@/types/property'
import { GoogleMap } from '@react-google-maps/api'
import * as React from 'react'
import { PropertyMarker } from '../setupButtons/Layer/PropertyMarker'
import { setup3Dbutton } from '../setupButtons/setup3Dbutton'

interface MapProps {
  zoom?: number
  csrfToken: string
  property_id?: number
  lat?: number | string
  lng?: number | string
  propertyLat?: number
  propertyLng?: number
  propertyShape?: LatLng[]
  existdAreaValue?: string
  areaTextFromResponse?: string
  areaShape?: string
  style?: React.CSSProperties
}

export const GoogleMapViewer = (
  {
    zoom = 19,
    csrfToken,
    property_id,
    lat,
    lng,
    propertyLat,
    propertyLng,
    propertyShape,
    existdAreaValue = '',
    areaTextFromResponse,
    areaShape,
    style,
  }: MapProps
) => {
  const mapOptions = React.useMemo(() => {
    return {
      center: { lat: Number(lat), lng: Number(lng) },
      zoom: zoom,
      minZoom: 1,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: true,
      rotateControl: false,
      fullscreenControl: true,
      tilt: 0,
      mapTypeId: 'roadmap',
    }
  }, [lat, lng])

  const mapRef = React.useRef(null)
  const onBoundsChanged = () => {
    const newBounds = mapRef.current.getBounds()
  }

  const onLoad = (map) => {
    mapRef.current = map

    const styledMapType = new google.maps.StyledMapType(
      [
        {
          elementType: 'labels.icon',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'transit.line', // 交通機関の路線
          elementType: 'all', // ラベルを含めてすべて
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'transit.station.rail', // 電車の駅
          elementType: 'all', // ラベルを含めてすべて
          stylers: [{ visibility: 'on' }],
        },
      ],
      { name: 'Styled Map' }
    )
    map.mapTypes.set('styled_map', styledMapType)
    map.setMapTypeId('styled_map')

    // MARK: 3D表示ボタン
    setup3Dbutton(map)

    const propertyMarker = new PropertyMarker({
      map,
      mode: 'public_file',
      property_id,
      propertyLat,
      propertyLng,
      propertyShape,
      areaTextFromResponse,
      siteArea: existdAreaValue,
      areaShape,
      csrfToken,
    })
    // 物件ピン, 敷地形状表示
    propertyMarker.setupPolygonAndShape()
  }

  const containerStyle = React.useMemo(() => {
    return (
      style ?? {
        width: '100%',
        height: '600px',
      }
    )
  }, [style])

  return (
    <>
      <GoogleMap
        mapContainerStyle={containerStyle}
        options={mapOptions}
        onLoad={onLoad}
        onBoundsChanged={onBoundsChanged}
      />
    </>
  )
}

GoogleMapViewer.displayName = 'GoogleMapViewer'